<template>
  <div>
    <h3 class="text-left">Feedback</h3>
    <div class="table-responsive">
      <table ref="feedback" class="table">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col">Type</th>
            <th scope="col">Feedback</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(feedback,index) in allFeedback" :key="feedback.id">
            <td>{{ index + 1 }}</td>
            <td>{{ feedback.userId?.name ?? "NA" }}</td>
            <td>{{ feedback.userId?.phone ?? "NA" }}</td>
            <td>{{ feedback.userId?.email ?? "NA" }}</td>
            <td>{{ feedback.userId?.type ?? "NA" }}</td>
            <td>{{ feedback.feedBack }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import Loader from "../components/common/Loader.vue";
export default {
  data() {
    return {
      menteeData: null,
      title: "",
      isLoading: false,
    };
  },
  components: {
    Loader,
  },
  computed: {
    ...mapGetters({ allFeedback: "auth/getAllFeedback" }),
  },
  methods: {
    feedbackDetails() {
      this.isLoading = true;
      this.$store.dispatch("auth/getAllFeedback").then(() => {
        $(this.$refs.feedback).DataTable({
          bPaginate: true,
          bInfo: false,
          order: false,
          language: {
            search: "", // Set your placeholder text here
            searchPlaceholder: "Search",
          },
          columnDefs: [
            { targets: "_all", orderable: false }, // Disable sorting for all columns
          ],
        });
        this.isLoading = false;
      });
    },
  },
  async mounted() {
    await this.feedbackDetails();
    console.log("data of feedback", this.allFeedback[3]);
  },
};
</script>
