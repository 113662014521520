<script>

import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props:{
    chartData:{
      type: Object,
      default:()=> {}
    },
    options: {
      type: Object,
      default: ()=> ({
        labels: [],
        datasets: [
          {
            label: 'Mentors',
            borderColor: '#2962ff',
            pointBackgroundColor: 'white',
            borderWidth: 2,
            backgroundColor: 'rgba(41, 98, 255, 0.5)',
            data: [],
          },
          {
            label: 'Mentees',
            borderColor: '#05CBE1',
            pointBackgroundColor: 'white',
            borderWidth: 2,
            backgroundColor: 'rgba(255, 255, 255, 0)',
            data: [],
          },
        ],
      })
    }
  },
  watch:{
    isRender(){
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options);
  },

};
</script>
