import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import About from "../views/About";
import AdminLayout from "../layouts/AdminLayout";
import Login from "../views/Login";
import NotFoundPage from "../views/notFoundPage";
import Mentee from "../views/Mentee";
import Mentor from "../views/Mentor";
import Languages from "../views/Languages";
import Nationalities from "../views/Nationalities";
import Dashboard from "../views/Dashboard";
import Services from "../views/Services";
import Profile from "../views/Profile";
import store from "../store";
import Industries from "../views/Industries";
import Degrees from "../views/Degrees";
import Feedback from "../views/Feedback";
import Reports from "../views/Reports";
import ReportDetail from "../views/ReportDetail";
import WelcomeText from "../views/WelcomeMessage";
import Announcement from "../views/Announcement";
import UserDetail from "../views/UserDetail";
import ServiceCategories from "../views/ServiceCategories";
import SubCategories from "../views/SubCategories";
import Forgotpassword from "../views/Forgotpassword";
import OtpVerification from "../views/OtpVerification";
import Changepassword from "../views/Changepassword";
import Updatepassword from "../views/Updatepassword";
import Chat from "../views/Chat";
import ThankYou from "../views/ThankYou";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path:"/thank-you",
    name:"ThankYou",
    component:ThankYou
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: Forgotpassword,
  },
  {
    path: "/otpverification",
    name: "otpverification",
    component: OtpVerification,
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: Changepassword,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFoundPage,
  },
  {
    path: "/",
    redirect: "Dashboard",
    component: AdminLayout,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/about",
        name: "About",
        component: About,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/mentee",
        name: "mentee",
        component: Mentee,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/mentor",
        name: "mentor",
        component: Mentor,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/languages",
        name: "Languages",
        component: Languages,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/nationalities",
        name: "Nationalities",
        component: Nationalities,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/industries",
        name: "Industries",
        component: Industries,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/degrees",
        name: "Degrees",
        component: Degrees,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/services",
        name: "Services",
        component: Services,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/feedback",
        name: "Feedback",
        component: Feedback,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/report/detail/:id",
        name: "ReportDetail",
        component: ReportDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/user/detail/:id",
        name: "UserDetail",
        component: UserDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sub/services/:id",
        name: "ServiceCategories",
        component: ServiceCategories,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sub/categories/:id",
        name: "SubCategories",
        component: SubCategories,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/welcome/text",
        name: "WelcomeText",
        component: WelcomeText,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/announcement",
        name: "Announcement",
        component: Announcement,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/updatepassword",
        name: "updatepassword",
        component: Updatepassword,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/chat",
        name: "chat",
        component: Chat,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const user = localStorage.getItem("user");
  const accessToken = localStorage.getItem("accessToken");
  const restrictedRoutes = [
    "forgotpassword",
    "otpverification",
    "changepassword",
  ];
  if (restrictedRoutes.includes(to.name) && (user || accessToken)) {
    next("/dashboard"); // Redirect to Dashboard or any other route
    return;
  }
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);

  if (requiresAuth) {
    if (user || accessToken) {
      next();
    } else {
      logout();
    }
  } else {
    const isrestrictedRoute = to.matched.some(
      (route) => route.name === "Login"
    );
    if (isrestrictedRoute && user) {
      next("/dashboard");
    } else {
      next();
    }
  }

  function logout() {
    store.dispatch("auth/routeLogout").then(() => {
      next({ name: "Login" });
    });
  }
});

export default router;
