<template>
  <div>
    <h3 class="mb-2 text-left">Mentee Listing</h3>
    <Loader v-if="isLoading" />
    <div class="table-responsive">
      <table ref="mentee" class="table">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(mentee,index) in allMentee" :key="mentee.id">
            <td>{{index + 1}}</td>
            <td>{{ mentee.name }}</td>
            <td>{{ mentee.phone ? mentee.phone : "NA" }}</td>
            <td>{{ mentee.email }}</td>
            <td>
              {{ mentee.isActive ? "Active" : "Deactive" }}
            </td>
            <td>
              <span
                v-if="mentee.status"
                @click="openModal(mentee)"
                class="btn btn-outline-success btn-sm cursor ml-2">
                Activated
              </span>
              <span
                v-else
                @click="openModal(mentee)"
                class="btn btn-outline-danger btn-sm cursor ml-2">Suspended</span>
              <span
                @click="showMenteeDetail(mentee)"
                class="btn btn-outline-secondary btn-sm cursor ml-2">Detail</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ConfirmationModal
      @emitStatusPermission="emitStatusPermission"
      :title="title"
    ></ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import { mapGetters } from "vuex";
import $ from "jquery";
import Loader from "../components/common/Loader.vue";
import helpers from '../helpers'
export default {
  data() {
    return {
      selectedIds: [],
      menteeData: null,
      title: "",
      isLoading: false,
    };
  },
  components: { ConfirmationModal, Loader },
  computed: {
    ...mapGetters({ allMentee: "auth/getAllMentee" }),
  },
  methods: {
    menteeDetails() {
      this.isLoading = true;
      this.$store.dispatch("auth/getAllMentee").then(() => {
        $(this.$refs.mentee).DataTable({
          bPaginate: true,
          bInfo: false,
          order: false,
          language: {
            search: "", // Set your placeholder text here
            searchPlaceholder: "Search",
          },
          columnDefs: [
            { targets: "_all", orderable: false }, // Disable sorting for all columns
          ],
        });
        this.isLoading = false;
      });
    },
    emitStatusPermission() {
      this.menteeData.status = !this.menteeData.status;
      let payload = {
        userId: this.menteeData._id,
        status: this.menteeData.status,
        isAdmin: true,
      };
      this.$store.dispatch("auth/userStatus", payload).then(() => {
        this.menteeData = null;
        this.closeModal();
        helpers.customMessage(this,'Success','Status updated successfully.','success');
      });
    },
    openModal(mentee) {
      if (mentee.status) {
        this.title = "Are you sure want to deactivate/suspend this account?";
      } else {
        this.title = "Are you sure want to activate this account?";
      }
      this.menteeData = mentee;
      $("#confirmationModal").modal("show");
    },
    closeModal() {
      $("#confirmationModal").modal("hide");
    },
    async showMenteeDetail(mentee) {
      if (mentee) {
        try {
          await this.$store.dispatch("auth/setUserDetail", { mentee });
          this.$router.push({ name: "UserDetail", params: { id: mentee._id } });
        } catch (error) {
          console.error("Error setting mentee details:", error);
        }
      }
    },
  },
  async mounted() {
    await this.menteeDetails();
  },
};
</script>
