<template>
  <div class="overflow-auto">
      <div class="d-flex gap-3 align-items-center mb-4">
        <img
          class="cursor-pointer"
          src="../assets/images/arrow-left.svg"
          alt="back arrow"
          @click="checkRoute"
          style="width: 30px"/>
        <h3 class="mb-0">Sub Categories</h3>
      </div>
    <table ref="mentee" class="table">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Sub Categories</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(service,index) in allServices" :key="service.id">
          <td v-if="service.sub_parent_id === parseInt(subServiceId) && service.sub_parent_id !== 0">{{ index + 1}}</td>
          <td v-if="service.sub_parent_id === parseInt(subServiceId) && service.sub_parent_id !== 0">
            {{ service.name }}
          </td>
          <td
            v-if="service.sub_parent_id === parseInt(subServiceId) &&service.sub_parent_id !== 0">
            <button
              @click="editService(service)"
              type="button"
              class="btn btn-sm btn-outline-info mr-2">
              Edit
            </button>
            <button
              @click="openConfirmationModal(service._id)"
              type="button"
              class="btn btn-sm btn-outline-danger mr-2">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <Loader v-if="isLoading" />
    <ServiceModal
      @emitSaveService="emitSaveService"
      @close="closeModal"
      :selectedService="selectedServiceForUpdate"
    ></ServiceModal>
    <ConfirmationModal :title="title" @emitStatusPermission="emitDeleteService">
    </ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import ServiceModal from "../components/Modal/servicesModal";
import { mapGetters } from "vuex";
import $ from "jquery";
import Helper from "../helper";
import helpers from '../helpers';
import Loader from "../components/common/Loader.vue";
export default {
  name: "SubServices",
  data() {
    return {
      selectedServiceForUpdate: null,
      serviceId: "",
      title: "Are you sure want to delete this service?",
      subServiceId: this.$route.params.id,
      isLoading: false,
    };
  },
  components: { ConfirmationModal, ServiceModal, Loader },
  computed: {
    ...mapGetters({
      allServices: "auth/getAllServices",
      services: "auth/getServices",
    }),
    filteredServices() {
      return this.allServices.filter((service) => {
        return (
          service.parent_id === parseInt(this.subServiceId) &&
          service.sub_parent_id !== 0
        );
      });
    },
  },
  methods: {
    checkRoute(){

      const data = this.allServices.find(service => service.id === this.subServiceId);

      this.$router.push({
        name: "ServiceCategories",
        params: { id: data.parent_id},
      });
    },
    async emitSaveService(name) {
      if (name === "") {
        return Helper.toast(
          this,
          "Alert",
          "Please enter service name first",
          "danger"
        );
      }
      let payload = {
        id: this.selectedServiceForUpdate._id,
        name: name,
      };
      await this.$store.dispatch("auth/updateServices", payload).then(() => {
        this.closeModal();
        helpers.customMessage(this,'success','Service updated successfully.','success');
      });
    },
    editService(service) {
      this.selectedServiceForUpdate = service;
      this.openModal();
    },
    emitDeleteService() {
      let payload = {
        id: this.serviceId,
      };
      this.$store.dispatch("auth/deleteService", payload).then((response) => {
        if(response.status === 200){
          this.closeConfirmationModal();
          helpers.customMessage(this,'Success','Service deleted successfully.','success');
        }else{
          this.closeConfirmationModal();
          helpers.customMessage(this,'Error',response.error[0],'error');
        }
      });
    },
    openModal() {
      $("#serviceModal").modal("show");
    },
    closeModal() {
      $("#serviceModal").modal("hide");
    },
    openConfirmationModal(id) {
      this.serviceId = id;
      $("#confirmationModal").modal("show");
    },
    closeConfirmationModal() {
      $("#confirmationModal").modal("hide");
    },
    async servicesDetail() {
      this.isLoading = true;
      await this.$store.dispatch("auth/getAllServices").then(() => {
        this.isLoading = false;
      });
    },
  },
  async mounted() {
    await this.servicesDetail();
  },
  watch: {},
};
</script>
