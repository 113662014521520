<template>
  <div class="modal fade" id="serviceModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Edit Service</h4>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-group">
            <input
              v-model="name"
              type="text"
              maxlength="50"
              placeholder="Enter service name"
              required
              class="form-control"/>
            <div
              class="error text-danger"
              v-if="$v.name.$error && !$v.name.required">
              service is required
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button @click="saveService" type="button" class="btn btn-outline-primary">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "Services",
  props: {
    selectedService: Object,
  },
  data() {
    return {
      name: "",
    };
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    saveService() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit("emitSaveService", this.name.trim());
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    selectedService(val) {
      this.name = val.name;
    },
  },
};
</script>
