<template>
  <div class="">
    <div class="mb-2">
      <h3 class="mb-3" style="text-align: start">Add Service</h3>
      <div class="row align-items-center">
        <div class="col-md-3 mb-2 mb-md-0 ml-0 ml-sm-2">
          <input
            v-model="service"
            type="text"
            required
            maxlength="30"
            placeholder="Enter Service name"
            class="form-control"
          />
        </div>
        <div class="col-md-3 mb-2 mb-md-0">
          <select class="custom-select" v-model="selectedService">
            <option
              v-for="service in services"
              :key="service.id"
              :value="service.id"
            >
              {{ service.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3 mb-2 mb-md-0">
          <select class="custom-select" v-model="selectedSubService">
            <option
              v-for="subService in subServices"
              :key="subService.id"
              :value="subService.id"
            >
              {{ subService.name }}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <button class="btn btn-sm btn-outline-info" @click="addService">
            Add Services
          </button>
        </div>
      </div>
    </div>

    <h3 class="mt-4 mb-0 text-left">All Services</h3>
    <table ref="servicesData" class="table mt-3">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Service</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(service,index) in filteredServices" :key="service.id">
          <td>{{ index + 1 }}</td>
          <td>{{ service.name }}</td>
          <td>
            <button
              @click="editService(service)"
              type="button"
              class="btn btn-sm btn-outline-info mr-2">
              Edit
            </button>
            <button
              @click="openConfirmationModal(service._id)"
              type="button"
              class="btn btn-sm btn-outline-danger mr-2">
              Delete
            </button>
            <button
              @click="showServicesDetail(service)"
              type="button"
              class="btn btn-sm btn-outline-secondary mr-2">
              Detail
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <Loader v-if="isLoading" />
    <ServiceModal
      @emitSaveService="emitSaveService"
      @close="closeModal"
      :selectedService="selectedServiceForUpdate"
    ></ServiceModal>
    <ConfirmationModal :title="title" @emitStatusPermission="emitDeleteService">
    </ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import ServiceModal from "../components/Modal/servicesModal";
import { mapGetters } from "vuex";
import helpers from '../helpers'
import $ from "jquery";
import Loader from "../components/common/Loader.vue";
export default {
  data() {
    return {
      isLoading: false,
      selectedService: "",
      selectedSubService: "",
      service: "",
      subServices: [],
      selectedServiceForUpdate: null,
      serviceId: "",
      title: "Are you sure want to delete this service?",
    };
  },
  components: { ConfirmationModal, ServiceModal, Loader },
  computed: {
    ...mapGetters({
      allServices: "auth/getAllServices",
      services: "auth/getServices",
    }),
    filteredServices() {
      // Exclude the first item from the services array
      return this.services.slice(1);
    }

  },
  methods: {
    async servicesDetail() {
      this.isLoading = true;
      await this.$store.dispatch("auth/getAllServices").then(() => {
        this.dateTableSet();
        this.isLoading = false;
      });
    },
    selectService(serviceId) {
      this.subServices = [];
      this.subServices = this.allServices.filter((obj) => obj.parent_id === serviceId && obj.sub_parent_id === 0);
      this.subServices.unshift({id:"", name:"Select sub services"});
    },
    async addService() {
      if (this.service === "") {
       return helpers.customMessage(this,'Error','Please enter service name first.','error');
      }
      let payload = {
        name: this.service,
        parent_id: this.selectedService,
        sub_parent_id: this.selectedSubService,
      };
      await this.$store.dispatch("auth/addServices", payload).then((response) => {
        if(response.status === 200){
          this.service = "";
          this.selectedService = "";
          this.selectedSubService = "";
          helpers.customMessage(this,'Success','Service added successfully.','success');
        }else {
          this.service = "";
          this.selectedService = "";
          this.selectedSubService = "";
          helpers.customMessage(this,'Error',response.error[0].message,'error');
        }
      });
    },
    dateTableSet(){
      $(this.$refs.servicesData).DataTable({
        bPaginate: true,
        bInfo: false,
        order: false,
        language: {
          search: "", // Set your placeholder text here
          searchPlaceholder: "Search",
        },
        columnDefs: [
          { targets: "_all", orderable: false }, // Disable sorting for all columns
        ],
      });
    },
    async emitSaveService(name) {
      if (name === "") {
       return helpers.customMessage(this,'Error','Please enter service name first.','error');
      }
      let payload = {
        id: this.selectedServiceForUpdate._id,
        name: name,
      };
      await this.$store.dispatch("auth/updateServices", payload).then(() => {
        this.closeModal();
        helpers.customMessage(this,'Success','Service updated successfully.','success');
      });
    },
    editService(service) {
      this.selectedServiceForUpdate = service;
      this.openModal();
    },
    emitDeleteService() {
      let payload = {
        id: this.serviceId,
      };
      this.$store.dispatch("auth/deleteService", payload).then((response) => {
        if(response.status === 200){
          this.closeConfirmationModal();
          helpers.customMessage(this,'Success','Service deleted successfully.','success');
        }else{
          this.closeConfirmationModal();
          helpers.customMessage(this,'Error',response.error[0],'error');
        }
      });
    },
    openModal() {
      $("#serviceModal").modal("show");
    },
    closeModal() {
      $("#serviceModal").modal("hide");
    },
    openConfirmationModal(id) {
      this.serviceId = id;
      $("#confirmationModal").modal("show");
    },
    closeConfirmationModal() {
      $("#confirmationModal").modal("hide");
    },
    showServicesDetail(service) {
      this.$router.push({
        name: "ServiceCategories",
        params: { id: service.id },
      });
    },
  },
  async mounted() {
    await this.servicesDetail();
  },
  watch: {
    selectedService(newVal, oldVal) {
      if (!newVal) return (this.subServices = []);
      if (oldVal !== newVal) {
        this.selectService(newVal);
      }
    },
  },
};
</script>
