<template>
  <div
    class="modal fade"
    id="languageModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">
            {{ selectedLanguage.name ? "Edit Language" : "Add Language" }}
          </h4>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-group">
            <input
              v-model="name"
              type="text"
              maxlength="30"
              placeholder="Enter Language name"
              class="form-control"/>
            <div class="error text-danger" v-if="$v.name.$error && !$v.name.required">
              Language is required
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button @click="saveLanguage" type="button" class="btn btn-outline-primary">
            {{ selectedLanguage.name ? "Update" : "Save" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "LanguageModal",
  props: {
    selectedLanguage: Object,
  },
  data() {
    return {
      name: "",
    };
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    saveLanguage() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit("emitSaveLanguage", this.name.trim());
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    selectedLanguage(val) {
      this.name = val.name;
    },
  },
};
</script>
