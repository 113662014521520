<template>
  <div class="modal fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->

        <div class="modal-header">
          <h4 class="modal-title">
            {{
              selectedNationality.name ? "Edit Nationality" : "Add Nationality"
            }}
          </h4>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-group">
            <input
              v-model="name"
              type="text"
              maxlength="50"
              placeholder="Enter nationality name"
              required
              class="form-control"
            />
            <div
              class="error text-danger"
              v-if="$v.name.$error && !$v.name.required"
            >
              Nationality is required
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button
            @click="saveNationality"
            type="button"
            class="btn btn-outline-primary"
          >
            {{ selectedNationality.name ? "Update" : "Save" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "Nationality",
  props: {
    selectedNationality: Object,
  },
  data() {
    return {
      name: "",
    };
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    saveNationality() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit("emitSaveNationality", this.name);
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    selectedNationality(val) {
      this.name = val.name;
    },
  },
};
</script>
