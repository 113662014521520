<template>
  <section class="section">
<!--     <Loader v-if="isLoading" />-->
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex gap-3 align-items-center">
            <img
              class="cursor-pointer"
              src="../assets/images/arrow-left.svg"
              alt="back arrow"
              v-if="userDetail"
              @click="checkRoute(userDetail)"
              style="width: 30px"/>
            <h2 v-if="userDetail" class="mb-0">{{userDetail.type === 'both' ? 'Mentor Detail': 'Mentee Detail'}}</h2>
          </div>
          <div class="candidate-list">
            <div class="candidate-list-box card mt-4" v-if="userDetail">
              <div class="p-4 card-body">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="candidate-list-images">
                      <img
                        v-if="userDetail.images && userDetail.images.profile"
                        :src="userDetail.images.profile"
                        alt=""
                        class="avatar-md img-thumbnail rounded-circle"
                      />
                      <img
                        v-else
                        src="https://bootdey.com/img/Content/avatar/avatar6.png"
                        alt=""
                        class="avatar-md img-thumbnail rounded-circle"
                      />
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="candidate-list-content mt-3 mt-lg-0 text-left">
                      <h5 class="fs-19 mb-0">
                        <span class="primary-link" href="#">{{userDetail.name}}</span>
                        <span
                          v-if="userDetail.menteeRating"
                          class="badge bg-danger ms-1">
                          {{ userDetail.menteeRating }}
                        </span>
                      </h5>
                      <!-- <p class="text-muted mb-2">
                        Type / {{ userDetail.type }}
                      </p> -->
                      <ul class="mt-2 list-inline mb-0 text-muted">
                        <li class="list-inline-item d-block">
                          Email :{{
                            userDetail.email ? userDetail.email : "NA"
                          }}
                        </li>
                        <li class="list-inline-item d-block">
                          Gender:
                          {{ userDetail.gender ? userDetail.gender : "NA" }}
                        </li>
                        <li class="list-inline-item d-block">
                          Phone:
                          {{ userDetail.phone ? userDetail.phone : "NA" }}
                        </li>
                        <li class="list-inline-item d-block">
                          Linked-in Id:
                          {{
                            userDetail.linkedinId ? userDetail.linkedinId : "NA"
                          }}
                        </li>
                        <li class="list-inline-item d-block">
                          Status:
                          {{ userDetail.status ? "Active" : "Suspended" }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-auto right-content-custom">
                    <div class="mt-2 d-flex flex-wrap align-items-center gap-3 about-wrapper">
                      <span>Speak:</span>
                      <div
                        class="d-flex gap-2 flex-wrap"
                        v-if="userDetail.speak.length > 0">
                        <span
                          v-for="speak in userDetail.speak"
                          :key="speak._id"
                          class="badge bg-secondary fs-14">{{ speak.name }}</span>
                      </div>
                      <div v-else>
                        <p class="fs-6 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <div
                      class="mt-2 d-flex flex-wrap align-items-center gap-3 about-wrapper">
                      <span>Nationality:</span>
                      <div
                        class="d-flex gap-2 flex-wrap"
                        v-if="userDetail.nationality.length > 0">
                        <span
                          v-for="nationality in userDetail.nationality"
                          :key="nationality._id"
                          class="badge bg-secondary fs-14"
                          >{{ nationality.name }}</span>
                      </div>
                      <div v-else>
                        <p class="fs-6 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <div
                      class="mt-2 d-flex flex-wrap align-items-center gap-3 about-wrapper">
                      <span>Industries:</span>
                      <div
                        class="d-flex gap-2 flex-wrap"
                        v-if="userDetail.industries.length > 0">
                        <span
                          v-for="industry in userDetail.industries"
                          :key="industry._id"
                          class="badge bg-secondary fs-14"
                          >{{ industry.name }}</span>
                      </div>
                      <div v-else>
                        <p class="mb-0 fs-14 custom-text">Not Available</p>
                      </div>
                    </div>
                    <div
                      class="mt-2 d-flex flex-wrap align-items-center gap-3 about-wrapper">
                      <span>Education:</span>
                      <div
                        class="d-flex gap-2 flex-wrap"
                        v-if="userDetail.education.length > 0">
                        <span
                          v-for="education in userDetail.education"
                          :key="education._id"
                          class="badge bg-secondary fs-14"
                          >{{ education.degree }}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">Not Available</p>
                      </div>
                    </div>
                    <div
                      class="mt-2 d-flex flex-wrap align-items-center gap-3 about-wrapper"
                    >
                      <span>Services:</span>
                      <div
                        class="d-flex gap-2 flex-wrap"
                        v-if="userDetail.service.length > 0"
                      >
                        <span
                          v-for="service in userDetail.service"
                          :key="service._id"
                          class="badge bg-secondary fs-14"
                          >{{ service.name }}</span
                        >
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'
// import Loader from "../components/common/Loader.vue";

export default {
  name: "UserDetail",
  data() {
    return {
      isLoading:true,
      detail:Object
    };
  },
  computed: {
    ...mapGetters({ userDetail: "auth/getUserDetail"}),
  },
  methods: {
    checkRoute(detail) {
      if (detail && detail.type === "both") {
        this.$router.push("/mentor");
      } else {
        this.$router.push("/mentee");
      }
    },
  },
};
</script>
<style scoped>
body {
  padding-top: 40px;
  background: #e6e6fa;
}
.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.avatar-md {
  height: 8rem;
  width: 8rem;
}
.candidate-list-box .about-wrapper > span {
  min-width: 100px;
  text-align: left;
  line-height: 25px;
}
.right-content-custom .custom-text {
  line-height: 25px;
}
.fs-19 {
  font-size: 19px;
}

.primary-link {
  color: #314047;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a {
  color: #02af74;
  text-decoration: none;
}

.bookmark-post .favorite-icon a,
.job-box.bookmark-post .favorite-icon a {
  background-color: #da3746;
  color: #fff;
  border-color: danger;
}
.favorite-icon a {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #eff0f2;
  border-radius: 6px;
  color: rgba(173, 181, 189, 0.55);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.candidate-list-box .favorite-icon {
  position: absolute;
  right: 22px;
  top: 22px;
}
.fs-14 {
  font-size: 14px;
}
.bg-soft-secondary {
  background-color: rgba(116, 120, 141, 0.15) !important;
  color: #74788d !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}
</style>
