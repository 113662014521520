<template>
  <div
    class="modal fade"
    id="confirmationModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="exampleModalLabel"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h5>{{ title }}</h5>
        </div>
        <div class="modal-footer">

          <button
            @click="statusPermission"
            type="button"
            class="btn btn-outline-info">
            Yes
          </button>

          <button
            type="button"
            class="btn btn-outline-danger"
            data-bs-dismiss="modal">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "confirmationModal",
  props: {
    title: String,
  },
  data() {
    return {};
  },
  methods: {
    statusPermission() {
      this.$emit("emitStatusPermission");
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
