<template>
  <section class="">
    <div class="container">
      <div class="main-veiw">
        <!-- <div class="top-nav">
          <div class="logo">hello</div>
        </div> -->
        <div class="text-left mb-3 channel-list-show-mobile"
          @click="showMobileListing">
          <i class="fas fa-bars"></i>
        </div>
        <div class="custom-content">
          <div class="message-list" :class="{ block: isShowMobile, none: !isShowMobile }">
            <div class="list-top">
              <input
                v-model="searchText"
                type="text"
                placeholder="Search here..."/>
              <!-- <button class="search">
                <i class="fas fa-search"></i>
              </button> -->
            </div>
            <div class="list">
              <div
                class="contact"
                v-for="(item, index) in filteredItemsList"
                :key="index"
                :class="{ active: item.isActive, unread: item.unread }"
                @click="toggleActive(index)">
                <div class="icon">
                  <img
                    :src="
                      item.userProfilePictureURL
                        ? item.userProfilePictureURL
                        : 'https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg'" alt="pics"/>
                </div>
                <div class="blurb">
                  <h2 @click="loadChatData(item)" class="name">
                    {{ item.userName }} <span style="font-size: 12px;" v-if="item.isSuspended">(Suspended)</span>
                    <span style="font-size: 12px;" v-if="item.isDeactive"> (Deactive)</span>
                  </h2>
                  <span
                    v-if="item['650430a6cf5882d969d84b0a$$'] > 0"
                    class="number-count-custom">
                    {{ item["650430a6cf5882d969d84b0a$$"] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="chat" v-if="messages.length === 0">
            <img
              class="massage-container-img"
              src="../assets/images/logo.png"
              alt=""/>
          </div>
          <div class="chat" v-else>
            <div class="top">
              <div class="open-message" v-if="channel">
                {{ channel.userName }}
              </div>
            </div>
            <div class="custom-massage-box">
              <div class="message-container" ref="chatBox">
                <div v-for="(message, index) in sortedMessages" :key="index">
                  <div class="other-wrapper" v-if="message.senderID !== user.user._id">
                    <div class="comment other">
                      <img :src="message.recipientProfilePictureURL
                            ? message.recipientProfilePictureURL
                            : 'https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg'" alt=""/>
                      <div v-if="isURL(message.url)" class="bubble">
                        <img :src="message.url" width="200" height="200" />
                      </div>
                      <div v-else class="bubble">{{ message.content }}</div>
                    </div>
                    <span class="msg-time-other">{{dateFormat(message.createdAt)}}</span>
                  </div>
                  <div class="me-wrapper" v-else>
                    <div class="comment me">
                      <img :src="message.senderProfilePictureURL
                            ? message.senderProfilePictureURL: 'https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg'" alt=""/>
                      <div v-if="isURL(message.content)" class="bubble">
                        <img :src="message.content" width="200" height="200" />
                      </div>
                      <div v-else class="bubble">{{ message.content }}</div>
                    </div>
                    <span class="msg-time-me">{{dateFormat(message.createdAt)}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-container">
              <label class="attach">
                <input
                  type="file"
                  ref="imageInput"
                  accept=".jpg, .jpeg, .png"
                  @change="uploadImage"
                  class="hidden-input"/>
                <i class="fa fa-paperclip"></i>
              </label>
              <div class="textarea-container">
                <p class="fileName" v-if="checkFileName">{{checkFileName}}</p>
                <textarea v-else
                  type="text"
                  v-model="content"
                  @keyup.enter="handleEnterKey($event)"
                  placeholder="Type...">
                </textarea>
              </div>
              <button class="send" @click="addNewThread">
                <i class="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </section>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.css";
import { firestore, storage } from "../firebaseConfig";
import { mapGetters } from "vuex";
import Loader from "../components/common/Loader.vue";
import helpers from '../helpers'
export default {
  name: "Chat",
  data() {
    return {
      title: "",
      content: "",
      participants: [],
      searchText: "",
      filteredItems: [],
      messages: [],
      channel: "",
      imageUrl: "",
      users: [],
      fcm_token: "",
      isLoading: false,
      isShowMobile: false,
      fileName:"",
      // userType:'',
    };
  },
  created() {
    // this.userType = localStorage.getItem('userType');
    // console.log("check the value of userType",this.userType);

    this.getChannels();
  },
  components: {
    Loader,
  },
  computed: {
    ...mapGetters({ user: "auth/getAuthUser" }),
    filteredItemsList() {
      if (!this.searchText) {
        return this.participants;
      }
      const searchTerm = this.searchText.toLowerCase();
      return this.participants.filter((item) => {
        return item.userName.toLowerCase().includes(searchTerm);
      });
    },

    sortedMessages() {
      return this.messages.slice().sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB; // Sort in descending order
      });
    },
    chatMessages() {
      if (!this.channel) {
        // Return an empty array or handle accordingly when no channel is selected.
        return [];
      }
      const channelCollection = firestore
        .collection("channels")
        .doc(this.channel.id)
        .collection("thread");
      const messages = [];
      // Use onSnapshot to listen for real-time updates
      channelCollection.onSnapshot(
        (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const threadData = { id: doc.id, ...doc.data() };
            messages.push(threadData);
          });
        },
        (error) => {
          console.error("Error fetching thread data:", error);
        }
      );
      return messages;
    },
    checkFileName(){
      return this.fileName;
    }
  },

  methods: {

    scrollToLastMessage() {
      // Use $refs to get a reference to the chat box element
      const chatBox = this.$refs.chatBox;
      // Get the last message in your messages array
      const lastMessageIndex = this.messages.length - 1;
      // Check if there are messages
      if (lastMessageIndex >= 0) {
        const lastMessage = chatBox.children[lastMessageIndex];
        // Scroll to the last message with smooth behavior
        lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    },

    dateFormat(createdAt) {
      if (createdAt) {
        const date = new Date(createdAt);
        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
        const day = dayNames[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = date.getMonth() + 1; // Month is zero-based, so add 1
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12; // Use 12 instead of 0 for 12 AM
        const formattedMinutes = String(minutes).padStart(2, "0");
        return `${dayOfMonth}-${month}-${year} ${day} ${formattedHours}:${formattedMinutes} ${ampm}`;
      } else {
        return "NA";
      }
    },
    isURL(text) {
      const urlPattern = /(https?:\/\/[^\s]+)/;
      return urlPattern.test(text);
    },
    uploadImage() {
      const file = this.$refs.imageInput.files[0];
      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        return helpers.customMessage(this,'Error','File size exceeds the limit (2MB). Please choose a smaller file.','error');
      }

      this.fileName = this.$refs.imageInput.files[0].name;
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`images/${file.name}`);
      imageRef
        .put(file)
        .then((snapshot) => {
          console.log("Image uploaded:", snapshot);
          return imageRef.getDownloadURL();
        })
        .then((imageUrl) => {
          console.log("Image URL:", imageUrl);
          // Set the image URL in the content state
          // this.content = imageUrl;
          this.imageUrl = imageUrl; // Optional: Store the URL separately if needed
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    },
    toggleActive(index) {
      // Reset isActive for all items
      this.participants.forEach((item) => {
        item.isActive = false;
      });
      // Set isActive to true for the clicked item
      this.participants[index].isActive = true;
    },
    userList() {
      const usersCollection = firestore.collection("User");
      usersCollection
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            this.users.push(userData);
          });
          console.log("Users Data:", this.users);
        })
        .catch((error) => {
          console.error("Error retrieving users:", error);
        });
    },
    getChannels() {
      this.isLoading = true;
      const participationCollection = firestore.collection("channel_participation");
      const query = participationCollection.where("user", "==", this.user.user._id);

      // let query;
      // if(this.userType === "both"){
      //   query = participationCollection.where("user", "==", this.user.user._id).where("type","==","both");
      // }else{
      //   query = participationCollection.where("user", "==", this.user.user._id);
      // }
      // Set up real-time listener for the query
      query.onSnapshot((querySnapshot) => {
        const fetchChannelPromises = [];
        querySnapshot.forEach((doc) => {
          const channelId = doc.data().channel;
          const channelCollection = firestore.collection("channels").doc(channelId);
          fetchChannelPromises.push(channelCollection.get());
        });
        // Fetch all channel documents in parallel
        Promise.all(fetchChannelPromises)
          .then((channelSnapshots) => {
            const channels = [];
            const existingChannelIDs = [];
            channelSnapshots.forEach((channelSnapshot) => {
              if (channelSnapshot.exists) {
                const channelData = {
                  id: channelSnapshot.id,
                  ...channelSnapshot.data(),
                };
                const channelID = channelData.channelID;
                if (!existingChannelIDs.includes(channelID)) {
                  // Add the channelID to the list of existing IDs
                  existingChannelIDs.push(channelID);
                  // Push the channelData into the channels array
                  channels.push(channelData);
                }
              }
            });
            this.participants = channels;
            console.log("Fetched channels:", channels);
            this.isLoading = false;
          })
          .catch((error) => {
            console.error("Error fetching documents:", error);
          });
      });
    },

    loadChatData(item) {
      this.isShowMobile = false;
      const userId = item.users[0];
      const matchingUsers = this.users.filter((x) => x.userId === userId);
      if (matchingUsers.length > 0) {
        this.fcm_token = matchingUsers[0]["token"];
      }
      this.channel = item;
      console.log("test channel this cannel id",item.id);
      const channelCollection = firestore.collection("channels").doc(item.id).collection("thread");
      // Set up a real-time listener for the messages collection
      channelCollection.onSnapshot((querySnapshot) => {
        const thread = [];
        querySnapshot.forEach((doc) => {
          const threadData = { id: doc.id, ...doc.data() };
          thread.push(threadData);
        });
        this.scrollToLastMessage();
        this.messages = thread;
      });

      const channelIndex = this.participants.findIndex(channel => channel.id === item.id);
      const fieldToUpdate = "650430a6cf5882d969d84b0a$$";
      const newValue = 0;
      const getChannel = firestore.collection("channels").doc(item.id);
      getChannel
        .update({
          [fieldToUpdate]: newValue,
        })
        .then(() => {
          this.channel = { ...item, [fieldToUpdate]: newValue };
          this.participants[channelIndex] = { ...item, [fieldToUpdate]: newValue };
          console.log("Field updated successfully");
        })
        .catch((error) => {
          console.error("Error updating field:", error);
        });
    },

    generateRandomString(length) {
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      const charsetLength = charset.length;
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charsetLength);
        result += charset.charAt(randomIndex);
      }
      return result;
    },
   async handleEnterKey(event) {
      if (!event.shiftKey) {
      await this.addNewThread();
      }
    },
    async addNewThread() {
      console.log("check the created:",this.$moment(new Date()).format("Y-MM-DD HH:mm:ss.SSS"));
      if (this.content.trim() === "" && this.imageUrl === "") {
        return;
      }
      this.isLoading = true;
      const message = this.content || this.imageUrl;
      const lastMessage = this.content ? this.content : "Photo";
      const messageId = this.generateRandomString(8);
      try {
        await this.saveThreadToFirestore(message, messageId, lastMessage);
        this.isLoading = false;
        // await this.updateChannelAndParticipants(messageId, lastMessage);
        await this.sendNotification(message, messageId, lastMessage);
      } catch (error) {
        console.error("Error adding thread: ", error);
      } finally {
        this.scrollToLastMessage();
      }
    },

    async saveThreadToFirestore(message, messageId, lastMessage) {
      const currentDateUTC = new Date().toISOString();

      const threadCollectionRef = firestore.collection("channels").doc(this.channel.id).collection("thread");
      console.log("ddfsd",lastMessage);
      const newObject = {
        content: message,
        created: currentDateUTC,
        createdAt: this.$moment(new Date()).format("Y-MM-DD HH:mm:ss.SSS"),
        id: messageId,
        lastMessageSeeners: [],
        messageId: messageId,
        recipientID: this.channel.users[0],
        recipientName: this.channel.userName,
        recipientProfilePictureURL: this.channel.userProfilePictureURL,
        senderID: this.user.user._id,
        senderName: this.user.user.name,
        senderProfilePictureURL: this.user.user.images ? this.user.user.images.profile : "",
      };

      await threadCollectionRef.doc(messageId).set(newObject);
      this.content = "";
      this.imageUrl = "";
      this.fileName = "";
      // this.messages.push(newObject);
    },

    async updateChannelAndParticipants(messageId, lastMessage) {
      let nextEnduser = "";
      this.channel.users.forEach((id) => {
        if (id !== this.user.user._id) {
          nextEnduser = id;
        }
      });
        const checkId = `${nextEnduser}$$`;
        const channelRef = firestore.collection("channels").doc(this.channel.id);
        const docSnapshot = await channelRef.get();
        console.log("check the call of thread",checkId,messageId);
        if (docSnapshot.exists) {
          const data = docSnapshot.data();
          let count = data[checkId] || 0;
          count += 1;
          const updateData = {
            [checkId]: count,
            lastMessage: lastMessage,
          };
          console.log("check the updated record of updateData",updateData);
          await channelRef.update(updateData);
        } else {
          console.error("Document does not exist");
        }

      const participantData = await this.fetchParticipantsData();
      let notificationData = {...this.channel, participants: participantData, type: 2 , isSupport:true};
      return notificationData;
    },

    async fetchParticipantsData() {
      const participationCollection = firestore.collection("channel_participation");
      const query = participationCollection.where("channel", "==",this.channel.channelID);
      const participantData = [];
      try {
        const querySnapshot = await query.get();
        querySnapshot.forEach((doc) => {
          participantData.push(doc.data());
        });
      } catch (error) {
        console.error("Error getting participants:", error);
      }
      return participantData;
    },

    async sendNotification(message, messageId, lastMessage) {
      const receiverFCMToken = this.fcm_token;
      if (receiverFCMToken) {
        const participantData = await this.updateChannelAndParticipants(messageId, lastMessage);
        const notification = {
          to: receiverFCMToken,
          notification: {
            title: "Customer Support",
            body: lastMessage,
          },
          data: participantData,
        };
        const authToken = `Bearer AAAA5Z1bdWw:APA91bH2J3TbY2kiqyeRNvuwU7d6OF24o6eFMTOBGXvjLdOjJUvRfWA1_7CQOX0YKVHVIp-_ijNnz_W8QRPnTxQ4g1FiO9VojnSImkUNP23c5COU8paI8xXe9u-WLq3f7dvhjIIn-Lkj`;
        try {
          await fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers: {
              Authorization: authToken,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(notification),
          });
          console.log("Successfully sent notification");
        } catch (error) {
          console.error("Error sending notification:", error);
        }
      }
    },

    showMobileListing() {
      this.isShowMobile = true;
    },
  },
  async mounted() {
    await this.userList();
  },
};
</script>

<style scoped>
.list::-webkit-scrollbar {
  width: 0;
}
.fileName{
  font-size: 15px;
  text-align: start;
}
/* Track */
.list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #669fcf;
  border-radius: 10px;
}

/* Handle */
.list::-webkit-scrollbar-thumb {
  background: #669fcf;
  border-radius: 10px;
}

/* Handle on hover */
.list::-webkit-scrollbar-thumb:hover {
  background: #669fcf;
}
/*======= Side Nav ==========*/

.side-nav {
  background: var(--grey-3);
  color: white;
  font-size: 25px;
  text-align: center;
  height: 100%;
  z-index: 2;
}
.list {
  margin-top: 10px;
  overflow-y: auto;
  height: calc(100% - 61px);
}
.side-nav .profile img {
  margin-top: 15px;
  width: auto;
  height: 60px;
  filter: grayscale(1);
  border-radius: 50%;
}

.icon-container {
  line-height: 60px;
  height: 60px;
}

.icon-container:hover {
  background: var(--grey-2);
}

.icon-container + .active {
  border-left: 8px solid #64af50;
}

.top-nav {
  border-bottom: 1px solid grey;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-nav .logo {
  text-align: center;
  font-size: 150%;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #64af50;
}

/* .main-veiw {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 8vh 92vh;
} */

.custom-content {
  display: grid;
  grid-template-columns: 40% auto;
  grid-template-rows: 100%;
  padding: 0;
  height: calc(100vh - 152px);
}
.channel-list-show-mobile {
  display: none;
}
@media screen and (min-width: 800px) {
  .custom-content {
    grid-template-columns: 25% auto;
  }
}

@media screen and (max-width: 640px) {
  .custom-content {
    grid-template-columns: auto;
  }
  .channel-list-show-mobile {
    display: block;
  }
}

/*=========Message List======*/

.message-list {
  background: #669fcf;
  height: 100%;
}

@media screen and (max-width: 640px) {
  .message-list {
    position: fixed;
    /* display: none; */
    z-index: 1;
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
  .message-list.none {
    opacity: 0;
    left: -300px;
    transition: all 0.3s ease-in-out;
  }
  .message-list.block {
    opacity: 1;
    left: 0;
    transition: all 0.3s ease-in-out;
  }
}

.message-list .list-top {
  padding: 5px 10px;
  margin-top: 5px;
}

.message-list input {
  display: inline;
  font-size: 16px;
  padding: 6px 1rem;
  border-radius: 30px;
  outline: none;
  border: none;
  background: white;
  color: #333;
  width: 100%;
}
.message-list .contact {
  display: grid;
  grid-template-columns: 25% auto;
  padding: 5px 10px;
  color: white;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .message-list .contact {
    display: grid;
    grid-template-columns: 60px auto;
  }
}

.message-list .active {
  background: #0f4a96;
}

.message-list .unread:before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 18px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #64af50;
  z-index: 1;
}
.message-list .blurb {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.message-list .name {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 100;
  text-align: left;
  color: #fff;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.message-list img {
  height: 100%;
  width: auto;
  max-height: 40px;
  border-radius: 50%;
  filter: grayscale(1);
  z-index: 2;
}

/*======== Chat Part ===========*/

.chat {
  display: grid;
  grid-template-rows: 7vh 64vh 7vh;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background: white;
  position: relative;
}

/*========= Top Nav ============*/

.chat .top {
  font-family: "Roboto", sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.chat .top .open-message {
  padding: 0 10px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
.chat .msg-time-me,
.msg-time-other {
  display: block;
  text-align: right;
  padding-right: 60px;
  font-size: 14px;
  color: #bbb;
}
.msg-time-other {
  text-align: left;
  padding-left: 60px;
}

@media only screen and (min-width: 640px) and (max-width: 1024px) {
  .chat {
    grid-template-rows: 7vh 60vh 7vh;
  }
}
@media screen and (max-width: 640px) {
  .chat .top .open-message {
    display: block;
  }
  /* .chat {
    grid-template-rows: 7vh 60vh 7vh;
  } */
}

@media only screen and (min-width: 577px) and (max-width: 639px) {
  .chat {
    grid-template-rows: 7vh 55vh 7vh;
  }
  .custom-content {
    height: calc(100vh - 192px);
  }
}
@media only screen and (max-width: 576px) {
  .chat {
    grid-template-rows: 7vh 61vh 7vh;
  }
  .custom-content {
    height: calc(100vh - 145px);
  }
}
.chat .top .name {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-left: 10px;
  width: 200px;
  cursor: pointer;
}

.chat .button-container {
  text-align: right;
  width: 75%;
}

.chat .button-container button {
  border: none;
  background: white;
  font-size: 20px;
  color: var(--grey-2);
}

.chat .button-container button:hover {
  color: #64af50;
  transition: color ease 0.5s;
}

/*======== Chat Message Container =====*/

.chat .message-container {
  padding: 15px 5px 5px;
  overflow-y: scroll;
  width: 98%;
  height: 95%;
  padding-right: 28px;
  position: relative;
  box-sizing: content-box;
}
.massage-container-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chat .divider {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #bbb;
  font-family: var(--font-1);
  text-align: center;
  position: relative;
  display: inline-block;
}

.chat .divider span {
  background: white;
  padding: 0px 10px;
}

.chat .divider:before {
  content: "";
  position: absolute;
  left: 0;
  top: 14px;
  height: 1px;
  width: 100%;
  background: #bbb;
  z-index: -1;
}

.chat .comment {
  min-height: 40px;
  width: 100%;
  float: left;
  /* margin: 5px 0px; */
  text-align: left;
  line-height: normal;
}
.chat .me-wrapper {
  margin-bottom: 5px;
}
.chat .other-wrapper {
  margin-bottom: 5px;
  list-style: normal;
}
.chat .me {
  float: right;
  text-align: right;
  line-height: normal;
}

.chat .comment img {
  height: 37px;
  width: 37px;
  border-radius: 50%;
  float: left;
}

.chat .me img {
  float: right;
}

.chat .bubble {
  display: inline-block;
  background: #64af50;
  padding: 8px 12px;
  border-radius: 8px;
  color: white;
  font-family: var(--font-1);
  max-width: 55%;
  position: relative;
  margin: 0px 15px;
}

.chat .me .bubble {
  background: #8dabe3;
  text-align: left;
}

.chat .other .bubble:before {
  content: "";
  top: 0px;
  left: -25px;
  position: absolute;
  background: transparent;
  height: 0px;
  width: 0px;
  border-left: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 20px solid #64af50;
}

.chat .me .bubble:after {
  content: "";
  position: absolute;
  top: 0px;
  right: -25px;
  background: transparent;
  height: 0px;
  width: 0px;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid #8dabe3;
}

.chat .bubble img {
  border-radius: 5px;
  height: 200px;
  width: auto;
  filter: none;
}

.chat .bubble a {
  color: white;
}

/*======== Typing part =========*/

.chat .input-container {
  display: grid;
  grid-template-columns: 6vh auto 6vh;
  padding: 10px 6px;
  border-top: 1px solid #ccc;
}

.chat .attach {
  border: none;
  border-radius: 50%;
  width: 100%;
  height: 8vh;
  max-height: 40px;
  max-width: 40px;
  position: relative;
  background: linear-gradient(180deg, #e6eaee 0%, #d5dee8 100%);
  cursor: pointer;
}
.chat .hidden-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.chat .attach .fa-paperclip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chat .textarea-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.chat textarea {
  border: none;
  outline: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  resize: none;
  overflow-y: scroll;
  width: 100%;
  height: 8vh;
  padding-right: 18px;
  box-sizing: content-box;
  margin-left: 10px;
}
.chat textarea::placeholder {
  color: lightgray;
}
.chat .send {
  border: none;
  background: #64af50;
  color: white;
  font-size: 20px;
  height: 8vh;
  width: 8vh;
  border-radius: 50%;
  max-height: 40px;
  max-width: 40px;
}
/*======= Contact List =======*/

.contact-detail {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background: #eee;
  display: none;
  font-family: "Roboto", sans-serif;
  padding: 0px 10px;
  overflow-y: scroll;
}

.close {
  font-size: 20px;
  border: none;
  background: transparent;
}

.contact-detail img {
  width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
  filter: grayscale(0);
  border-radius: 50%;
}

.contact-detail h3 {
  color: var(--grey-2);
  text-align: center;
  margin: 8px 0px;
  font-size: 22px;
}

.contact-detail .bio {
  text-align: center;
}

.contact-detail ul {
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
}
.number-count-custom{
  background: #0f4a96;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.contact-detail ul li {
  list-style: none;
  width: 100%;
  background: white;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-radius: 8px;
  margin-bottom: 5px;
}

.contact-detail ul li .contact-img {
  border-radius: none;
  height: auto;
  width: 55px;
  margin: 0 10px;
  display: inline-block;
  filter: grayscale(1);
}

.contact-list ul li .name {
  display: inline-block;
}
</style>
