import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery';
window.$ = window.jQuery = $;
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import '@mdi/font/css/materialdesignicons.css';
import "./assets/scss/style.css";
import moment from 'vue-moment';
import VueSweetalert2 from 'vue-sweetalert2';


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(moment)
Vue.use(VueSweetalert2);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
