<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="mb-3">
        <h5 for="exampleFormControlTextarea" class="form-label text-left">
          Welcome Text
        </h5>
        <textarea
          style="border-radius: 5px !important"
          v-model="menteeText"
          class="form-control rounded-lg"
          placeholder="Enter message here for mentee"
          id="exampleFormControlTextarea"
          rows="5">
        </textarea>
      </div>
    </div>
    <!--    <div class="col-sm-6">-->
    <!--      <div class="mb-3">-->
    <!--        <h5 for="exampleFormControlTextarea1" class="form-label text-left">-->
    <!--          Message For Mentor-->
    <!--        </h5>-->
    <!--        <textarea-->
    <!--          v-model="mentorText"-->
    <!--          class="form-control rounded-lg"-->
    <!--          placeholder="Enter message here for mentor"-->
    <!--          id="exampleFormControlTextarea1"-->
    <!--          rows="3"-->
    <!--        ></textarea>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="text-right">
      <button @click="clearText" class="btn btn-outline-info mr-2">
        Clear
      </button>
      <button @click="saveText" class="btn btn-outline-info px-4">
        Save
      </button>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import helpers from "../helpers";
import Loader from "../components/common/Loader.vue";
export default {
  name: "WelcomeText",
  data() {
    return {
      menteeText: "",
      mentorText: "",
      isLoading: false, // Add a loading indicator
    };
  },
  computed: {
    ...mapGetters({ welcomeText: "auth/getWelcomeData" }),
  },
  comments: {
    Loader,
  },
  methods: {
    async getWelcomeData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("auth/getWelcomeData");
        this.menteeText = this.welcomeText.menteeText;
        this.mentorText = this.welcomeText.mentorText;
        this.isLoading = false;
      } catch (error) {
        helpers.errorMessage(this, error.message || "Error fetching data");
      }
    },
    clearText() {
      this.menteeText = "";
    },
    async saveText() {
      if (this.menteeText === "") {
        return helpers.customMessage(this,'Error','Please enter welcome message first.','error');
      }
      let id = "";
      if (this.welcomeText) {
        id = this.welcomeText._id;
      }
      const payload = {
        id: id,
        menteeText: this.menteeText,
        mentorText: this.mentorText,
      };
      try {
        const response = await this.$store.dispatch(
          "auth/saveWelcomeData",
          payload
        );
        if (response.status === 200) {
          helpers.customMessage(this,'Success','Welcome text updated successfully.','success');
        } else {
          helpers.customMessage(this,'Error',response.error[0],'error');
        }
      } catch (error) {
        helpers.errorMessage(this, error.message || "Error saving data");
      }
    },
  },
  async mounted() {
    await this.getWelcomeData();
  },
  components: { Loader },
};
</script>
