<template>
  <div>
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Industries</h3>
      <button
        @click="addIndustry"
        class="btn btn-sm btn-outline-info mr-2 float-right">
        Add Industry
      </button>
    </div>
    <div class="table-responsive">
      <table ref="industries" class="table">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(industry,index) in allIndustries" :key="industry.id">
            <td>{{ index + 1 }}</td>
            <td>{{ industry.name }}</td>
            <td>
              <button
                @click="editIndustry(industry)"
                type="button"
                class="btn btn-sm btn-outline-info mr-2"
              >
                Edit
              </button>
              <button
                @click="openConfirmationModal(industry._id)"
                type="button"
                class="btn btn-sm btn-outline-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-if="isLoading" />

    <IndustryModal
      @emitSaveIndustry="submitSaveIndustry"
      @close="closeModal"
      :selectedIndustry="selectedIndustry"
    ></IndustryModal>
    <ConfirmationModal
      @emitStatusPermission="emitDeleteIndustry"
      :title="title"
    ></ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import IndustryModal from "../components/Modal/IndustryModal";
import { mapGetters } from "vuex";
import $ from "jquery";
import helpers from "../helpers";
import Loader from "../components/common/Loader.vue";
export default {
  data() {
    return {
      selectedIndustry: {
        id: "",
        name: "",
      },
      title: "",
      industryId: "",
      isLoading: false,
    };
  },
  components: { IndustryModal, ConfirmationModal, Loader },
  computed: {
    ...mapGetters({ allIndustries: "auth/getAllIndustries" }),
  },
  methods: {
    empty() {
      this.selectedIndustry = {
        id: "",
        name: "",
      };
    },
    industriesDetails() {
      this.isLoading = true;
      this.$store.dispatch("auth/getAllIndustries").then(() => {
        this.dataTableSet();
        this.isLoading = false;
      });
    },

    addIndustry() {
      this.empty();
      this.openModal();
    },
    submitSaveIndustry(name) {
      if (this.selectedIndustry.name) {
        let payload = {
          id: this.selectedIndustry._id,
          name: name,
        };
        this.$store.dispatch("auth/updateIndustry", payload).then((x) => {
          if (x.status === 200) {
            // Successfully updated industry, now update the DataTable
            this.updateDataTable();
            this.empty();
            this.closeModal();
            helpers.customMessage(this,'Success','Industry updated successfully.','success');
          } else {
            helpers.customMessage(this,'Error',x.error[0].message,'error');
          }
        });
      } else {
        let payload = {
          name: name,
        };
        this.$store.dispatch("auth/saveIndustry", payload).then((x) => {
          if (x.status === 200) {
            // Successfully added industry, now update the DataTable
            this.updateDataTable();
            this.closeModal();
            helpers.customMessage(this,'Success','Industry added successfully.','success');
          } else {
            helpers.customMessage(this,'Error',x.error[0].message,'error');

          }
        });
      }
    },
    dataTableSet(){
      // Recreate the DataTable with the updated data
      $(this.$refs.industries).DataTable({
        bPaginate: true,
        bInfo: false,
        order: false,
        language: {
          search: "", // Set your placeholder text here
          searchPlaceholder: "Search",
        },
        columnDefs: [
          { targets: "_all", orderable: false }, // Disable sorting for all columns
        ],
      });
    },
    updateDataTable() {
        // Assuming this.$refs.industries refers to your DataTable element
        if ($.fn.DataTable.isDataTable(this.$refs.industries)) {
          // Destroy the DataTable instance
          $(this.$refs.industries).DataTable().destroy();
          this.dataTableSet();
        }
      },

    emitDeleteIndustry() {
      let payload = {
        id: this.industryId,
      };
      this.$store.dispatch("auth/deleteIndustry", payload).then((x) => {
        if (x.status === 200) {
          this.closeConfirmationModal();
          helpers.customMessage(this,'Success','Industry deleted successfully.','success');
        } else {
          helpers.customMessage(this,'Error',x.error[0].message,'error');
        }
      });
    },
    openModal() {
      $("#industryModal").modal("show");
    },
    closeModal() {
      $("#industryModal").modal("hide");
    },
    editIndustry(industry) {
      this.selectedIndustry = industry;
      this.openModal();
    },
    openConfirmationModal(industryId) {
      this.title = "Are you sure want to delete this industry?";
      this.industryId = industryId;
      $("#confirmationModal").modal("show");
    },
    closeConfirmationModal() {
      $("#confirmationModal").modal("hide");
    },
  },
  async mounted() {
    await this.industriesDetails();
  },
};
</script>
