export default {

  SET_AUTH_USER (state, response) {
    localStorage.setItem('user', JSON.stringify(response.data.data))
    state.user = response.data.data
    state.isUserLogin = true
    state.token = localStorage.setItem('accessToken', response.data.data.accessToken)
  },
  SET_OTP_EMAIL (state, response) {
    console.log('state', state, 'response', response)
    state.verifiedEmail = response.email
  },

  LOGOUT_AUTH_USER (state) {
    localStorage.removeItem('user')
    localStorage.removeItem('accessToken')
    state.user = {}
    state.isUserLogin = false
  },
  SET_USER_DETAIL(state,mentee){
    state.userDetail = {}
    localStorage.setItem('userDetail', JSON.stringify(mentee))
    state.userDetail = mentee;
  },
  SET_REPORT_DETAIL(state,report){
    state.reportDetail = {}
    localStorage.setItem('reportDetail', JSON.stringify(report))
    state.reportDetail = report;
  },
  GET_ALL_MENTEE(state,response){
    state.allMentee = response.data.data;
  },
  GET_ALL_MENTOR(state,response){
    state.allMentor = response.data.data;
  },
  GET_ALL_LANGUAGES(state,response){
    state.allLanguages = response.data.data;
  },
  GET_ALL_NATIONALITIES(state,response){
    state.allNationalities = response.data.data;
  },

  GET_ALL_INDUSTRIES(state,response){
    state.allIndustries = response.data.data;
  },

  GET_ALL_DEGREES(state,response){
    state.allDegrees = response.data.data;
  },

  GET_STATS(state,response){
    state.stats = response.data.data.counts;
    state.graphData = response.data.data.countsByMonth;
  },
  SAVE_LANGUAGE(state,response){
    state.allLanguages.unshift(response.data.data);
  },

  UPDATE_LANGUAGE(state,response){
    console.log("test response",response.data.data)
    let index = state.allLanguages.findIndex(x => x._id === response.data.data._id);
    if (index !== -1) {
      state.allLanguages[index].name = response.data.data.name
    }
  },

  DELETE_LANGUAGE(state, response) {
    let language = state.allLanguages.findIndex(x => x._id === response.data.data._id);
    if (language !== -1) {
      state.allLanguages.splice(language, 1)
    }
  },

  SAVE_NATIONALITY(state,response){
    state.allNationalities.unshift(response.data.data);
  },

  SAVE_INDUSTRY(state,response){
    state.allIndustries.unshift(response.data.data);
  },

  SAVE_DEGREE(state,response){
    state.allDegrees.unshift(response.data.data);
  },

  SAVE_WELCOME_DATA(state,response){
    state.welcomeData={}
    state.welcomeData = response.data.data
  },

  UPDATE_DEGREE(state,response){
    let industry = state.allDegrees.findIndex(x => x._id === response.data.data._id);
    if (industry !== -1) {
      state.allDegrees[industry].name = response.data.data.name
    }
  },
  UPDATE_INDUSTRY(state,response){
    let industry = state.allIndustries.findIndex(x => x._id === response.data.data._id);
    if (industry !== -1) {
      state.allIndustries[industry].name = response.data.data.name
    }
  },

  UPDATE_NATIONALITY(state,response){
    let index = state.allNationalities.findIndex(x => x._id === response.data.data._id);
    if (index !== -1) {
      state.allNationalities[index].name = response.data.data.name
    }
  },
  UPDATE_SERVICES(state,response){
    let service = state.allServices.findIndex(x => x._id === response.data.data._id);
    if (service !== -1) {
      state.allServices[service].name = response.data.data.name
    }
  },

  DELETE_NATIONALITY(state, response) {
    let nationality = state.allNationalities.findIndex(x => x._id === response.data.data._id);
    if (nationality !== -1) {
      state.allNationalities.splice(nationality, 1)
    }
  },
  DELETE_DEGREE(state, response) {
    console.log("gtes",response.data);
    let degree = state.allDegrees.findIndex(x => x._id === response.data.data._id);
    if (degree !== -1) {
      state.allDegrees.splice(degree, 1)
    }
  },
  DELETE_INDUSTRY(state, response) {
    let industry = state.allIndustries.findIndex(x => x._id === response.data.data._id);
    if (industry !== -1) {
      state.allIndustries.splice(industry, 1)
    }
  },

  GET_ALL_SERVICES(state,response){
    state.allServices = response.data.data;
    state.services = []
    state.services = state.allServices.filter((obj) => obj.parent_id === 0)
    state.services.unshift({id:'',name:'Select service'})
  },
  ADD_SERVICES(state,response){
    if(response.data.data.parent_id ===0){
      state.services.push(response.data.data)
    }
    state.allServices.unshift(response.data.data)
  },
  DELETE_SERVICE(state, response) {
    let service = state.services.findIndex(x => x.id === response.data.data.id);
    if (service !== -1) {
      state.services.splice(service, 1)
    }
    let allService = state.allServices.findIndex(x => x.id === response.data.data.id);
    if (allService !== -1) {
      state.allServices.splice(allService, 1)
    }
  },
  USER_STATUS(state,response){
    let mentee = state.allMentee.findIndex(x => x._id === response.data.data.user._id);
    if (mentee !== -1) {
      state.allMentee[mentee].status = response.data.data.user.status
    }
    let mentor = state.allMentor.findIndex(x => x._id === response.data.data.user._id);
    if (mentor !== -1) {
      state.allMentor[mentor].status = response.data.data.user.status
    }
  },
  GET_ALL_FEEDBACK(state,response){
    state.allFeedback = response.data.data
  },
  GET_ALL_REPORT(state,response){
    state.allReports = response.data.data
  },
  GET_WELCOME_DATA(state,response){
    state.welcomeData = response.data.data
  },
  UPDATE_AUTH_USER(state, response) {
    let data = JSON.parse(localStorage.getItem('user'));
    data.user.images.profile = response.data.images.profile;
    localStorage.setItem('user', JSON.stringify(data));
    state.user = data;
  },

  UPDATE_AUTH_USER_PROFILE(state, response){
    let data = JSON.parse(localStorage.getItem('user'));
    data.user.name = response.data.data.user.name;
    localStorage.setItem('user', JSON.stringify(data));
    state.user = data;
  }
}
