import axios from '../../axios.js'

export default {
  async login ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/login', payload).then((response) => {
        console.log("response", response)
        if(response.data.status===200){
          commit('SET_AUTH_USER', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async updateprofile ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/user/profile/update', payload).then((response) => {
        if(response.data.status===200){
          commit('UPDATE_AUTH_USER_PROFILE', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async updateprofileimage({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axios.post('/user/upload_image', formData, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8' // Set appropriate content type
        }
      }).then((response) => {
        if (response.data.status === 200) {
          commit('UPDATE_AUTH_USER', response.data); // Assuming your response contains updated user dat
      }
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  async verifyemail ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/forgot_password', payload).then((response) => {
        console.log("response", response)
        if(response.data.status===200){
          commit('SET_OTP_EMAIL', payload)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async verifyotp ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/otp_verification', payload).then((response) => {
        console.log("response", response)
        if(response.data.status===200)
        console.log(commit);
        // {
        //   commit('SET_OTP_EMAIL', payload)
        // }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async changePassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/change_password', payload).then((response) => {
        console.log("response", response)
        if(response.data.status===200)
        console.log(commit);
        // {
        //   commit('SET_OTP_EMAIL', payload)
        // }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async updatePassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/change_password', payload).then((response) => {
        console.log("response", response)
        console.log("commit", commit)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async logout ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/logout', payload).then((response) => {
        commit('LOGOUT_AUTH_USER')
        resolve(response.data)
      }).catch((error) => {
        commit('LOGOUT_AUTH_USER')
        reject(error)
      })
    })
  },
  async routeLogout ({ commit }) {
    commit('LOGOUT_AUTH_USER')
  },
  async setUserDetail({commit},{mentee}){
    commit('SET_USER_DETAIL',mentee)
  },
  async setReportDetail({commit},{report}){
    commit('SET_REPORT_DETAIL',report)
  },
  async getAllMentee({commit,payload}) {
    return new Promise((resolve, reject) => {
      axios.post('/user/all/mentee/list', payload).then((response) => {
        commit('GET_ALL_MENTEE',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getAllMentor({commit,payload}) {
    return new Promise((resolve, reject) => {
      axios.post('/user/all_mentors',payload).then((response) => {
        commit('GET_ALL_MENTOR',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getAllLanguages({commit}) {
    return await new  Promise((resolve, reject) => {
       axios.get('/seeder/languages').then((response) => {
        commit('GET_ALL_LANGUAGES',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getAllFeedback({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/user/get/all/feedback').then((response) => {
        commit('GET_ALL_FEEDBACK',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async getAllReport({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/user/get/all/reports').then((response) => {
        commit('GET_ALL_REPORT',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getAllNationalities({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/seeder/nationalities').then((response) => {
        commit('GET_ALL_NATIONALITIES',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getAllIndustries({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/seeder/industries').then((response) => {
        commit('GET_ALL_INDUSTRIES',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async getWelcomeData({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/user/get/welcome/data').then((response) => {
        commit('GET_WELCOME_DATA',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getAllDegrees({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/seeder/degrees').then((response) => {
        commit('GET_ALL_DEGREES',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async saveWelcomeData({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/user/save/welcome/support', payload).then((response) => {
        if(response.data.status===200) {
          commit('SAVE_WELCOME_DATA', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async sendAnnouncementMessage({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/user/announcement', payload).then((response) => {
        if(response.data.status===200) {
          console.log("testing1122",commit);
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async saveDegree({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/degree', payload).then((response) => {
        if(response.data.status===200) {
          commit('SAVE_DEGREE', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async updateDegree({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/update/degree', payload).then((response) => {
        if(response.data.status===200) {
          commit('UPDATE_DEGREE', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async deleteDegree({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/delete/degree', payload).then((response) => {
        if(response.data.status===200) {
          commit('DELETE_DEGREE', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async saveIndustry({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/industry', payload).then((response) => {
        if(response.data.status===200) {
          commit('SAVE_INDUSTRY', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async updateIndustry({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/update/industry', payload).then((response) => {
        if(response.data.status===200) {
          commit('UPDATE_INDUSTRY', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async getStats({commit}) {
    return new Promise((resolve, reject) => {
      console.log("stats data")
      axios.get('/user/stats').then((response) => {
        commit('GET_STATS',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async saveLanguage({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/languages', payload).then((response) => {
        if(response.data.status===200) {
          commit('SAVE_LANGUAGE', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async updateLanguage({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/update/language', payload).then((response) => {
        if(response.data.status===200) {
          commit('UPDATE_LANGUAGE', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async deleteLanguage({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/delete/language', payload).then((response) => {
        if(response.data.status===200) {
          commit('DELETE_LANGUAGE', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async saveNationality({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/nationalities', payload).then((response) => {
        if(response.data.status===200) {
          commit('SAVE_NATIONALITY', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async updateNationality({commit},payload ) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/update/nationality', payload).then((response) => {
        if(response.data.status===200) {
          commit('UPDATE_NATIONALITY', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async deleteNationality({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/delete/nationality', payload).then((response) => {
        if(response.data.status===200) {
          commit('DELETE_NATIONALITY', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async deleteIndustry({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/delete/industry', payload).then((response) => {
        if(response.data.status===200) {
          commit('DELETE_INDUSTRY', response)
        }
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async getAllServices({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/seeder/categories').then((response) => {
        commit('GET_ALL_SERVICES',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async addServices({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/categories/', payload).then((response) => {
        commit('ADD_SERVICES',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async updateServices({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/update/category/', payload).then((response) => {
        commit('UPDATE_SERVICES',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async deleteService({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/seeder/delete/category', payload).then((response) => {
        commit('DELETE_SERVICE',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  async userStatus({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/user/user_status', payload).then((response) => {
        commit('USER_STATUS',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getuser({commit,payload}) {
    return new Promise((resolve, reject) => {
      axios.post('/user/profile',payload).then((response) => {
        commit('GET_USER',response)
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

}
