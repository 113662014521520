
const helpers = {
  successMessage(vm, success) {
    vm.$bvToast.toast(success, {
      title: "Success",
      variant: "success",
      solid: true,
      toaster: "b-toaster-top-center"
    });
  },
  errorMessage(vm,message){
    vm.$bvToast.toast(message, {
          title: "Error",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center"
    });
  },
  customMessage(vm,title,text,icon){
    vm.$swal({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: 'OK',
      confirmButtonColor: '#0294d3',
      cancelButtonColor: '#d33',
    });
  },
};
export default helpers;
