<template>
  <div class="main p-3 d-flex align-items-center justify-content-center">
    <div class="box-form">
      <h5>Change Password</h5>
      <div class="inputs">
        <input
          v-model="maskedCurrentPassword"
          :disabled="true"
          type="password"
          placeholder="Current Password"
        />
        <br />
        <input
          v-model="newPassword"
          type="password"
          placeholder="New Password"
        />
        <div class="error" v-if="$v.$error && !$v.newPassword.required">
          New Password is required
        </div>
        <div class="error" v-else-if="$v.$error && !$v.newPassword.minLength">
          New Password should be at least 6 characters long
        </div>
        <br />
        <input
          v-model="confirmPassword"
          type="password"
          placeholder="Confirm New Password"
        />
        <div class="error" v-if="$v.$error && !$v.confirmPassword.required">
          Confirm Password is required
        </div>
        <div
          class="error"
          v-else-if="!$v.$error && newPassword !== confirmPassword"
        >
          Passwords do not match
        </div>
      </div>
      <button @click="changePassword" class="change-button">
        Change Password
      </button>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import helpers from "../helpers";
import { mapGetters } from "vuex";

export default {
  name: "updatepassword",
  data() {
    return {
      email: "",
      currentPassword: "", // New field for current password
      newPassword: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapGetters({ userData: "auth/getAuthUser" }),
    maskedCurrentPassword() {
      return this.currentPassword.length > 8
        ? "*".repeat(8)
        : "*".repeat(this.currentPassword.length);
    },
  },
  validations: {
    currentPassword: {
      required,
    },
    newPassword: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      required,
    },
  },
  methods: {
    async changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.newPassword !== this.confirmPassword) {
        console.log("Passwords do not match");
        return;
      }

      let payload = {
        email: this.email,
        password: this.newPassword,
      };
      try {
        await this.$store.dispatch("auth/updatePassword", payload).then((response) => {
          if (response.status === 200) {
           return helpers.customMessage(this,'Success','Password updated successfully.','success');
          } else {
            helpers.customMessage(this,'Error',response.error[0],'error');
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    console.log("verified email", this.email);
    if (this.userData.user) {
      this.email = this.userData.user.email;
      this.currentPassword = this.userData.user.password;
    }
    console.log("userdetail", this.userData.user);
  },
};
</script>

<style scoped>
.box-form {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.box-form h5 {
  font-size: 24px;
  margin-bottom: 20px;
}

.inputs {
  margin-bottom: 20px;
}

.inputs input {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  color: #495057;
  border-radius: 30px;
  border: 1px solid #0f4a9670 !important;
  margin-bottom: 15px;
}

.error {
  color: red;
  margin-top: 5px;
}

.change-button {
  width: 100%;
  max-width: 250px;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  outline: 0;
  box-shadow: 0px 4px 20px 0px #3b5998;
  background: #3b5998;
}

.change-button:hover {
  background-color: #2980b9;
}
</style>
