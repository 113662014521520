export default {
  allMentee:[],
  allMentor:[],
  allLanguages:[],
  allNationalities:[],
  allIndustries:[],
  allServices:[],
  services:[],
  allDegrees:[],
  allFeedback:[],
  allReports:[],
  stats:{
    activeUsers: 0,
    inactiveUsers: 0,
    mentees: 0,
    mentors: 0,
    totalUsers: 0,
  },
  graphData:[],
  welcomeData:{},
  verifiedEmail:'',
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { id: '' },
  userDetail: localStorage.getItem('userDetail') ? JSON.parse(localStorage.getItem('userDetail')) : {
    speak: [],
    industries: [],
    nationality: [],
    education: [],
    service: [],
  },
  isUserLogin: !!localStorage.getItem('user'),
  token: localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') : '',
  reportDetail: localStorage.getItem('reportDetail') ? JSON.parse(localStorage.getItem('reportDetail')) : {
     reportTo:{
       speak: [],
       industries: [],
       nationality: [],
       education: [],
       service: [],
       images:{
         profile:''
       }
     },
    reportBy:{
      speak: [],
      industries: [],
      nationality: [],
      education: [],
      service: [],
      images:{
        profile:''
      }
    }
  }
}
