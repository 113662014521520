<template>
  <div>
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <h3 class="mb-0 text-left">Nationalities</h3>
      <button @click="addNationality" class="btn btn-sm btn-outline-info">
        Add Nationality
      </button>
    </div>
    <div class="table-responsive">
      <table class="table" ref="nationalities">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(nationality,index) in allNationalities" :key="nationality.id">
            <td>{{ index + 1}}</td>
            <td>{{ nationality.name }}</td>
            <td>
              <button
                @click="editNationality(nationality)"
                type="button"
                class="btn btn-sm btn-outline-info mr-2"
              >
                Edit
              </button>
              <button
                @click="openConfirmationModal(nationality._id)"
                type="button"
                class="btn btn-sm btn-outline-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-if="isLoading" />
    <NationalityModal
      @emitSaveNationality="submitSaveNationality"
      @close="closeModal"
      :selectedNationality="selectedNationality"
    ></NationalityModal>
    <ConfirmationModal
      @emitStatusPermission="emitDeleteLanguage"
      :title="title"
    ></ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import NationalityModal from "../components/Modal/NationalityModal";
import { mapGetters } from "vuex";
import $ from "jquery";
import helpers from "../helpers";
import Loader from "../components/common/Loader.vue";

export default {
  data() {
    return {
      selectedNationality: {
        id: "",
        name: "",
      },
      title: "",
      nationalityId: "",
      isLoading: false,
    };
  },
  components: { NationalityModal, ConfirmationModal, Loader },
  computed: {
    ...mapGetters({ allNationalities: "auth/getAllNationalities" }),
  },
  methods: {
    empty() {
      this.selectedNationality = {
        id: "",
        name: "",
      };
    },
    nationalitiesDetails() {
      this.isLoading = true;
      this.$store.dispatch("auth/getAllNationalities").then(() => {
        this.dataTableSet();
        this.isLoading = false;
      });
    },
    addNationality() {
      this.empty();
      this.openModal();
    },
    submitSaveNationality(name) {
      if (this.selectedNationality.name) {
        let payload = {
          id: this.selectedNationality._id,
          name: name,
        };
        this.$store.dispatch("auth/updateNationality", payload).then((x) => {
          if (x.status === 200) {
            // Successfully updated nationality, now update the DataTable
            this.updateDataTable();
            this.empty();
            this.closeModal();
            helpers.customMessage(this,'Success','Nationality updated successfully.','success');
          } else {
            helpers.customMessage(this,'Error',x.error[0].message,'error');
          }
        });
      } else {
        let payload = {
          name: name,
        };
        this.$store.dispatch("auth/saveNationality", payload).then((x) => {
          if (x.status === 200) {
            // Successfully added nationality, now update the DataTable
            this.updateDataTable();
            this.closeModal();
            helpers.customMessage(this,'Success','Nationality added successfully.','success');
          } else {
            this.closeModal();
            helpers.customMessage(this,'Error',x.error[0].message,'error');
          }
        });
      }
    },
      updateDataTable() {
        // Assuming this.$refs.nationalities refers to your DataTable element
        if ($.fn.DataTable.isDataTable(this.$refs.nationalities)) {
          // Destroy the DataTable instance
          $(this.$refs.nationalities).DataTable().destroy();
          // Recreate the DataTable with the updated data
          this.dataTableSet();
        }
      },
    dataTableSet () {
      $(this.$refs.nationalities).DataTable({
        bPaginate: true,
        bInfo: false,
        order: false,
        language: {
          search: "", // Set your placeholder text here
          searchPlaceholder: "Search",
        },
        columnDefs: [
          { targets: "_all", orderable: false }, // Disable sorting for all columns
        ],
      });
    },
    emitDeleteLanguage() {
      let payload = {
        id: this.nationalityId,
      };
      this.$store.dispatch("auth/deleteNationality", payload).then((x) => {
        if (x.status === 200) {
          this.closeConfirmationModal();
          helpers.customMessage(this,'Success','Nationality deleted successfully.','success');
        } else {
          helpers.customMessage(this,'Error',x.error[0].message,'error');
        }
      });
    },
    openModal() {
      $("#myModal").modal("show");
    },
    closeModal() {
      $("#myModal").modal("hide");
    },
    editNationality(nationality) {
      this.selectedNationality = nationality;
      this.openModal();
    },
    openConfirmationModal(nationalityId) {
      this.title = "Are you sure want to delete this nationality?";
      this.nationalityId = nationalityId;
      $("#confirmationModal").modal("show");
    },
    closeConfirmationModal() {
      $("#confirmationModal").modal("hide");
    },
  },
  async mounted() {
    await this.nationalitiesDetails();
  },
};
</script>

<style scoped>
.nationalities-table thead th,
.nationalities-table tbody td {
  width: 50% !important;
}
</style>
