import axios from 'axios'

export default axios.create({
  // baseURL: 'http://localhost:3000/api/',
  baseURL: 'https://mmentor.app/api/',
  // baseURL: 'http://movementor.nexteksol.com/api/',
  headers: {
    Authorization: {
      toString () {
        return `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
  }
})
