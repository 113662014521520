<template>
  <div class="Total-user-card mt-3">
    <b-card-group deck>
      <!-- Total Users Card -->
      <b-card border-variant="info">
        <h3>Total User</h3>
        <b-card-text>{{ stats.totalUsers }}</b-card-text>
        <!-- <b-progress variant="info" :value="(stats.mentees / totalUsers) * 100"></b-progress> -->
      </b-card>

      <!-- Mentor Card -->
      <b-card
        border-variant="danger"
        header-border-variant="danger"
        header-text-variant="danger"
        align="start">
        <h3 class="text-danger">Mentee</h3>
        <b-card-text>{{ stats.mentees }}</b-card-text>
        <!-- <b-progress variant="danger" :value="(stats.mentors / totalUsers) * 100"></b-progress> -->
      </b-card>

      <!-- Mentee Card -->
      <b-card border-variant="warning" header-bg-variant="transparent">
        <h3 class="text-warning">Mentor</h3>
        <b-card-text>{{ stats.mentors }}</b-card-text>
        <!-- <b-progress variant="warning" :value="(stats.mentees / totalUsers) * 100"></b-progress> -->
      </b-card>
      <!-- Active Users Card -->
      <b-card
        border-variant="primary"
        header-border-variant="primary"
        header-text-variant="primary"
      >
        <h3 class="text-primary">Active Users</h3>
        <b-card-text>{{ stats.activeUsers }}</b-card-text>
        <!-- <b-progress variant="primary" :value="(stats.activeUsers / totalUsers) * 100"></b-progress> -->
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserCard",
  computed: {
    ...mapGetters({ stats: "auth/getStats" }),
  },
};
</script>

<style scoped>
.Total-user-card .progress {
  height: 10px !important;
}
.Total-user-card .card-deck .card {
  border-radius: 10px;
}
.Total-user-card .card-body {
  padding: 20px 10px !important;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}
.Total-user-card .card-body h3 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
}
.Total-user-card .card-header:first-child {
  border-bottom: none;
}
.Total-user-card .card-text:last-child {
  font-size: 35px;
  line-height: 45px;
  padding: 0 10px;
}
@media only screen and (max-width: 991px) {
.Total-user-card .card-deck .card {
  flex: 1 0 33.33%;
  margin-bottom: 10px;
}
}



</style>
