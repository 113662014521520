<template>
  <div class="main p-3 d-flex align-items-center vh-100">
    <div class="box-form">
      <h5 class="title">Verify Otp</h5>
      <div class="inputs">
        <input
          v-model="otp"
          type="text"
          maxlength="6"
          placeholder="Enter your otp"
        />
      </div>
      <button @click="verifyotp" type="submit" class="verify-button">
        Verify Otp
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import helpers from "../helpers";

export default {
  name: "otpverification",
  data() {
    return {
      otp: "",
    };
  },
  computed: {
    ...mapGetters({ email: "auth/getverifiedEmail" }),
  },
  methods: {
    async verifyotp() {
      let payload = {
        email: this.email,
        otp: parseInt(this.otp),
      };
      console.log("payload", payload);

      try {
        await this.$store.dispatch("auth/verifyotp", payload).then((x) => {
          if (x.status === 200) {
            this.$router.push("/changepassword");
          } else {
            helpers.customMessage(this,'Error',x.error[0].message.error,'error');
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    console.log("verified email", this.email);
  },
};
</script>

<style scoped>
.box-form {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #3498db;
}

.inputs {
  margin-bottom: 20px;
}

.inputs input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #0f4a9670;
  border-radius: 5px;
}

.error {
  color: red;
  margin-top: 5px;
}

.verify-button {
  display: block;
  width: 100%;
  padding: 12px 24px;
  font-size: 18px;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.verify-button:hover {
  background-color: #2980b9;
}
</style>
