<template>
  <div>
    <h3 class="text-left">Reports</h3>
    <div class="table-responsive">
      <table ref="reports" class="table">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Date</th>
            <th scope="col">ReportBy</th>
            <th scope="col">ReportTo</th>
            <th scope="col">Type</th>
            <th scope="col">Reason</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(report,index) in allReports" :key="report.id">
            <td>{{ index + 1 }}</td>
            <td>{{ dateFormat(report.createdAt) }}</td>
            <td>{{ report.reportBy?.name ?? "NA" }}</td>
            <td>{{ report.reportTo?.name ?? "NA" }}</td>
            <td>{{ report.type ?? "NA" }}</td>
            <td>{{ report.reason }}</td>
            <td>
              <span
                @click="showReportDetail(report)"
                class="btn btn-sm btn-sm btn-outline-secondary">Detail
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-if="isLoading"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import Loader from "../components/common/Loader.vue";
export default {
  name: "Reports",
  data() {
    return {
      title: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({ allReports: "auth/getAllReports" }),
  },
  components: {
    Loader,
  },
  methods: {
    dateFormat(originalDateString){
      const originalDate = new Date(originalDateString);
      const formattedTime = originalDate.toLocaleTimeString([], { hour12: false });
      const formattedDate = originalDate.toLocaleDateString('en-CA'); // 'en-CA' uses the format 'yyyy/MM/dd'
      return `${formattedTime} ${formattedDate}`;
    },
    reportDetails() {
      this.isLoading = true;
      this.$store.dispatch("auth/getAllReport").then(() => {
        $(this.$refs.reports).DataTable({
          bPaginate: true,
          bInfo: false,
          order: false,
          language: {
            search: "", // Set your placeholder text here
            searchPlaceholder: "Search",
          },
          columnDefs: [
            { targets: "_all", orderable: false }, // Disable sorting for all columns
          ],
        });
        this.isLoading = false;
      });
    },
    async showReportDetail(report) {
      if (report) {
        try {
          await this.$store.dispatch("auth/setReportDetail", { report });
          this.$router.push({ name: "ReportDetail", params: { id: report._id } });
        } catch (error) {
          console.error("Error setting mentee details:", error);
        }
      }
    },
  },
  async mounted() {
    await this.reportDetails();
    console.log("asdas", this.allReports);
  },
};
</script>
