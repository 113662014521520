<template>
  <div class="container">
    <Loader v-if="isLoading" />
    <form>
      <div class="user_details">
        <!-- <div class="position-relative">  -->
        <div class="position-relative image-placeholder-inner">
          <div class="text-start choose-file">
            <img v-if="userDetail.profile" :src="userDetail.profile" class="image--profile" />
            <img v-else :src="defaultImage" class="image--profile" />
            <input class="profile-upload" type="file" id="profile" @change="handleImageUpload('profile', $event)"
              accept="image/*" />
            <i class="fas fa-plus"></i>
          </div>
        </div>
        <!-- </div> -->
        <div class="row input-details-wrapper">
          <div class="col-md-6 input_box">
            <label for="name">Name</label>
            <input v-model="userDetail.name" type="text" id="name" placeholder="Enter your name" required />
            <div class="error" v-if="$v.$error && !$v.userDetail.name.required">Name is required</div>
            <div class="error" v-else-if="$v.$error && !$v.userDetail.name.maxLength">Name should not be greater than 100
              words
            </div>
          </div>
          <div class="col-md-6 input_box">
            <label for="email">Email</label>
            <input v-model="userDetail.email" :disabled="true" style="background-color: rgb(244 242 242);"
                   type="text" id="email" placeholder="Enter your email"
              required/>
          </div>
        </div>
      </div>
      <div class="reg_btn">
        <router-link to="/updatepassword" class="update-password-button">
          <div class="update-password-label">Update Password</div>
        </router-link>
        <input @click="submitForm" type="button" class="save-button" value="Save" />
      </div>
    </form>
  </div>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import Loader from "../components/common/Loader.vue";
import { mapGetters } from "vuex";
import helpers from "../helpers";

export default {
  name: "Profile",
  data() {
    return {
      userDetail: {
        id: "",
        name: "",
        email: "",
        profile: "",
        cover: "",
      },
      isLoading: false,
      defaultImage: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
      coverImage:
        "https://images.pexels.com/photos/268941/pexels-photo-268941.jpeg?auto=compress&cs=tinysrgb&w=600",
    };
  },
  components: { Loader },
  computed: {
    ...mapGetters({ userData: "auth/getAuthUser" }),
  },
  validations: {
    userDetail: {
      name: {
        required,
        maxLength: maxLength(100),
      },
    },
  },
  methods: {
    async handleImageUpload(type, event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append("userId", this.userData.user._id);
      formData.append("type", type);
      formData.append("image", file);
      formData.append("isadmin", true);

      try {
        this.isLoading = true;
        const response = await this.$store.dispatch("auth/updateprofileimage", formData)
          .then((x) => {
            if (x.status === 200) {
              this.userDetail.profile = x.data.images.profile;
              this.isLoading = false;
              helpers.customMessage(this, 'Success', 'image uploaded successfully.', 'success');
            } else {
              helpers.customMessage(this, 'Error', response.error[0], 'error');
            }
          });
      } catch (error) {
        helpers.errorMessage(this, "An error occurred during image upload");
      }
    },
    async submitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      let payload = {
        id: this.userData.user._id,
        name: this.userDetail.name,
        email: this.userDetail.email,
      };
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch("auth/updateprofile", payload);
        if (response.status === 200) {
          helpers.customMessage(this, 'Success', 'profile updated successfully.', 'success');
        } else {
          helpers.customMessage(this, 'Error', response.error[0], 'error');
        }
        this.isLoading = false;
      } catch (error) {
        helpers.errorMessage("An error occurred while updating profile");
      }
    },
  },
  mounted() {
    if (this.userData.user) {
      this.userDetail.name = this.userData.user.name;
      this.userDetail.email = this.userData.user.email;
      if (this.userData.user.images && this.userData.user.images.profile) {
        this.userDetail.profile = this.userData.user.images.profile;
      }
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 700px;
  width: 100%;
  padding-top: 75px;
  /* overflow: hidden; */
  margin: 0 auto;
}

.container form {
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 5px 12px -2px rgba(0, 0, 0, 0.1), 0px 18px 36px -6px rgba(0, 0, 0, 0.1);
}

.container .title {
  padding: 25px;
  background: #f6f8fa;
}

.container .title p {
  font-size: 25px;
  font-weight: 500;
  position: absolute;
}

.container .title p::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 3px;
  background: linear-gradient(to right, #f37a65, #d64141);
}

.user_details {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; */
  padding: 0 25px 15px 25px;
  position: relative;
}

.input-details-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 85px;
}

.image--profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  object-fit: cover;
  object-position: center right;

}

/* .choose-file::before{
  content: "+";
  position: absolute;
  right: -30px;
  font-size: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
} */
.image-placeholder-inner {
  width: 150px;
  margin-inline: auto;
}

.image-placeholder-inner .fas.fa-plus {
  position: absolute;
  bottom: 25px;
  right: 0px;
  border-radius: 50%;
  border: 1px solid lightgray;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.image--cover {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-top: 1px solid #ccc;
  /* Add a border to separate cover image from profile image */
}

.user_details .input_box {
  /* width: calc(100% / 2 - 20px); */
  margin: 0 0 12px 0;
}

.choose-file {
  position: relative;
  width: 150px;
  height: 150px;
  position: absolute;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.input_box label {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  text-align: left;
}

.input_box select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: dimgray;
}

.input_box label::after {
  content: " *";
  color: red;
}

.input_box input {

  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  color: #495057;
  border-radius: 30px;
  border: 1px solid #0f4a9670 !important;
}


form .gender {
  padding: 0px 25px;
}

.gender .gender_title {
  font-size: 20px;
  font-weight: 500;
}

.gender .category {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.gender .category label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.gender .category label .dot {
  height: 18px;
  width: 18px;
  background: #d9d9d9;
  border-radius: 50%;
  margin-right: 10px;
  border: 4px solid transparent;
  transition: all 0.3s ease;
}

#radio_1:checked~.category label .one,
#radio_2:checked~.category label .two,
#radio_3:checked~.category label .three {
  border-color: #d9d9d9;
  background: #d64141;
}

.gender input {
  display: none;
}

.reg_btn {
  padding: 25px;

  display: flex;
  justify-content: center;
  /* Center the buttons horizontally */
}

.reg_btn {
  padding: 15px 10px;
  /* margin: 15px 0; */
  display: flex;
  justify-content: flex-end;
}

.reg_btn input,
.reg_btn .update-password-button {
  width: 100%;
  max-width: 150px;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  outline: 0;
  box-shadow: 0px 4px 20px 0px #3b5998;
  background: #3b5998;

}

.reg_btn .button-separator {
  width: 325px;
  /* Adjust the space between buttons as needed */
}

.reg_btn input:hover,
.reg_btn .update-password-button:hover {
  color: #fff !important;
  background: #0294d3 !important;

}

.update-password-label {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the label horizontally */
  height: 100%;
  width: 100%;
}

.error {
  color: red;
  margin-top: 5px;
}

.reg_btn input {

  width: 100%;
  max-width: 150px;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  outline: 0;
  box-shadow: 0px 4px 20px 0px #3b5998;
  background: #3b5998;
  margin-left: 10px;
}

.profile-upload {
  /* width: 170px;
  height: 170px;
  border: 3px solid #fff;
  box-shadow: 0 0 10px rgba(0,0,0,.3);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center right;
  object-position: center right;
  position: absolute;
  left: 50%;
  top: 0%;
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%,-50%); */
  width: 150px;
  height: 150px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  opacity: 0;
  z-index: 1;
}

/* @media screen and (max-width: 584px) { */
@media screen and (max-width: 767px) {
  .user_details {
    max-height: 430px;
    /* overflow-y: scroll; */
  }

  .user_details::-webkit-scrollbar {
    width: 0;
  }

  .user_details .input_box {
    width: 100%;
  }

  .gender .category {
    width: 100%;
  }
}


@media screen and (max-width: 419px) {
  .gender .category {
    flex-direction: column;
  }
}</style>
