<template>
  <div>
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Degree's</h3>
      <button @click="addDegree" class="btn btn-sm btn-outline-info">
        Add Degree
      </button>
    </div>
    <div class="table-responsive">
      <table ref="degrees" class="table">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(degree,index) in allDegrees" :key="degree.id">
            <td>{{ index + 1 }}</td>
            <td>{{ degree.name }}</td>
            <td>
              <button
                @click="editDegree(degree)"
                type="button"
                class="btn btn-sm btn-outline-info mr-2">
                Edit
              </button>
              <button
                @click="openConfirmationModal(degree._id)"
                type="button"
                class="btn btn-sm btn-outline-danger">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-if="isLoading" />
    <DegreeModal
      @emitSaveDegree="submitSaveDegree"
      @close="closeModal"
      :selectedDegree="selectedDegree"
    ></DegreeModal>
    <ConfirmationModal
      @emitStatusPermission="emitDeleteDegree"
      :title="title"
    ></ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import DegreeModal from "../components/Modal/DegreeModal";
import { mapGetters } from "vuex";
import $ from "jquery";
import helpers from "../helpers";
import Loader from "../components/common/Loader.vue";
export default {
  data() {
    return {
      selectedDegree: {
        id: "",
        name: "",
      },
      title: "",
      degreeId: "",
      isLoading: false,
    };
  },
  components: { DegreeModal, ConfirmationModal, Loader },
  computed: {
    ...mapGetters({ allDegrees: "auth/getAllDegrees" }),
  },
  methods: {
    empty() {
      this.selectedDegree = {
        id: "",
        name: "",
      };
    },
    degreesDetail() {
      this.isLoading = true;
      this.$store.dispatch("auth/getAllDegrees").then(() => {
        this.dataSetTable();
        this.isLoading = false;
      });
    },
    addDegree() {
      this.empty();
      this.openModal();
    },
    submitSaveDegree(name) {
      const payload = {
        name: name,
      };
      if (this.selectedDegree.name) {
        payload.id = this.selectedDegree._id;
        this.$store
          .dispatch("auth/updateDegree", payload)
          .then((response) => {
            if (response.status === 200) {
              // Successfully updated degree, now update the DataTable
              this.updateDataTable();
              this.empty();
              this.closeModal();
              helpers.customMessage(this,'Success','Degree updated successfully.','success');
            } else {
              helpers.customMessage(this,'Error',response.error[0].message,'error');
            }
          })
          .catch((error) => {
            console.error("An error occurred while updating:", error);
          });
      } else {
        this.$store
          .dispatch("auth/saveDegree", payload)
          .then((response) => {
            if (response.status === 200) {
              // Successfully added degree, now update the DataTable
              this.updateDataTable();
              this.closeModal();
              helpers.customMessage(this,'Success','Degree added successfully.','success');
            } else {
              helpers.customMessage(this,'Error',response.error[0].message,'error');
            }
          })
          .catch((error) => {
            console.error("An error occurred while saving:", error);
          });
      }
    },
      updateDataTable() {
        // Assuming this.$refs.degrees refers to your DataTable element
        if ($.fn.DataTable.isDataTable(this.$refs.degrees)) {
          // Destroy the DataTable instance
          $(this.$refs.degrees).DataTable().destroy();
          // Recreate the DataTable with the updated data
          this.dataSetTable();
        }
      },
    dataSetTable(){
      $(this.$refs.degrees).DataTable({
        bPaginate: true,
        bInfo: false,
        order: false,
        language: {
          search: "", // Set your placeholder text here
          searchPlaceholder: "Search",
        },
        columnDefs: [
          { targets: "_all", orderable: false }, // Disable sorting for all columns
        ],
      });
    },

    emitDeleteDegree() {
      let payload = {
        id: this.degreeId,
      };
      this.$store.dispatch("auth/deleteDegree", payload).then((x) => {
        if (x.status === 200) {
          this.closeConfirmationModal();
          helpers.customMessage(this,'Success','Degree deleted successfully.','success');
        } else {
          helpers.customMessage(this,'Error',x.error[0].message,'error');
        }
      });
    },
    openModal() {
      $("#degreeModal").modal("show");
    },
    closeModal() {
      $("#degreeModal").modal("hide");
    },
    editDegree(degree) {
      this.selectedDegree = degree;
      this.openModal();
    },
    openConfirmationModal(degreeId) {
      this.title = "Are you sure want to delete this Degree ?";
      this.degreeId = degreeId;
      $("#confirmationModal").modal("show");
    },
    closeConfirmationModal() {
      $("#confirmationModal").modal("hide");
    },
  },
  async mounted() {
    await this.degreesDetail();
  },
};
</script>
