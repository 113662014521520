<template>
  <div
    class="main custom-login d-flex align-items-center justify-content-center vh-100"
  >
    <Loader v-if="isLoading" />
    <div class="login-wrapper">
      <div class="box-form">
        <SplashScreen />
        <div class="right">
          <h5>Login</h5>
          <!-- <p>Don't have an account? it takes less than a minute</p> -->
          <div class="inputs">
            <input
              v-model="email"
              type="email"
              maxlength="100"
              placeholder="Email"
              @keyup.enter="handleLoginClick"/>
            <div
              class="error text-left px-3"
              v-if="$v.$error && !$v.email.required">
              Email is required
            </div>
            <div class="error text-left px-3"
              v-else-if="$v.$error && !$v.email.email">
              Please enter the valid email.
            </div>
            <div class="password-input-container">
              <input
                v-model="password"
                maxlength="40"
                class="password-icon"
                required
                :type="showPassword ? 'password' : 'text'"
                placeholder="Password"
                @keyup.enter="handleLoginClick"/>
              <img
                v-if="showPassword"
                src="../assets/images/eye-w.svg"
                alt="Open Eye"
                class="password-toggle-img"
                @click="togglePasswordEye"/>
              <img
                v-else
                src="../assets/images/eye-slash.svg"
                alt="Closed Eye"
                class="password-toggle-img"
                @click="togglePasswordEye"/>
            </div>
            <div
              class="error text-left px-3"
              v-if="$v.password.$error && !$v.password.required">
              Password is required
            </div>
          </div>
          <div class="text-center mt-3 w-100 remember-me--forget-password">
            <a
              style="color: #655d5d !important; font-size: 15px !important"
              @click="ForgotPassword"
              class="Forget-passwrd"
              >Forgot Password?</a>
          </div>
          <br />
          <!-- <button @click="loginAdmin" type="button">Login</button> -->
          <div class="login-button-wrapper text-end">
            <Button
              label="Login"
              buttonClass="primary-button"
              @click="handleLoginClick"
              type="button"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
import { required } from "vuelidate/lib/validators";
import SplashScreen from "../components/common/SplashScreen.vue";
import Button from "../components/common/Button.vue";
import Loader from "../components/common/Loader.vue";

import helpers from "../helpers";

export default {
  name: "Login",
  components: {
    SplashScreen,
    Button,
    Loader,
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: true,
      isLoading:false
    };
  },
  validations: {
    email: {
      required,
      email: (value) => emailRegex.test(value),
    },
    password: {
      required,
    },
  },
  methods: {
    ForgotPassword() {
      this.$router.push("/forgotpassword");
    },
    togglePasswordEye() {
      this.showPassword = !this.showPassword;
    },
    async handleLoginClick() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let payload = {
        email: this.email,
        password: this.password,
        isSocial: false,
        type:true
      };
      try {
        this.isLoading = true;
        await this.$store.dispatch("auth/login", payload).then((x) => {
          if (x.status === 200) {
            this.$router.push("/dashboard");
          } else {
            helpers.customMessage(this, 'Error', x.error[0], 'error');
          }
        });
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
  .password-icon{
    padding-right: 30px !important;
  }
.main {
  max-width: 80%;
  margin: auto;
}
.login-wrapper {
  width: 800px;
}
.box-form {
  /* width: 80%; */
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  /* height: 75%; */
}

.login-button-wrapper {
  max-width: 80%;
  margin-inline: auto;
}

.box-form .right {
  padding: 90px 40px;
  overflow: hidden;
  width: 50%;
}

.box-form .right h5 {
  font-size: 2rem;
  line-height: 0;
  font-weight: 400;
  line-height: 33px;
}

.box-form .right p {
  font-size: 14px;
  color: #b0b3b9;
}

.box-form .right .inputs {
  overflow: hidden;
}

.password-input-container {
  position: relative;
}

.password-toggle-img {
  position: absolute;
  right: 10px;
  top: 54%;
  /* transform: translateY(-50%); */
  cursor: pointer;
  width: 20px;
  height: 20px;
  user-select: none;
  z-index: 2;
}

.box-form .right input {
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  font-size: 16px;
  border: none;
  outline: none;
  border: 1px solid #0f4a9670 !important;
  border-radius: 30px;
}

.box-form .right .remember-me--forget-password {
  cursor: pointer;
}

.box-form .right .remember-me--forget-password input {
  margin: 0;
  margin-right: 7px;
  width: auto;
}

.SplashScreen a {
  color: #3b5998;
  cursor: pointer;
}

label {
  display: block;
  position: relative;
  margin-left: 30px;
}

label::before {
  content: " \f00c";
  position: absolute;
  font-family: FontAwesome;
  background: transparent;
  border: 3px solid #70f570;
  border-radius: 4px;
  color: transparent;
  left: -30px;
  transition: all 0.2s linear;
}

label:hover::before {
  font-family: FontAwesome;
  content: " \f00c";
  color: #fff;
  cursor: pointer;
  background: #70f570;
}

label:hover::before .text-checkbox {
  background: #70f570;
}

label span.text-checkbox {
  display: inline-block;
  height: auto;
  position: relative;
  cursor: pointer;
  transition: all 0.2s linear;
}
label input[type="checkbox"] {
  display: none;
}

.error {
  color: red;
}

@media (max-width: 991px) {
  .box-form {
    flex-flow: wrap;
    text-align: center;
    align-content: center;
    align-items: center;
  }
  .box-form .right {
    width: 100%;
  }
  .main.custom-login {
    height: 100% !important;
    max-width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .main.custom-login {
    height: 100% !important;
    max-width: 100%;
  }
  .box-form {
    width: 100%;
  }
  .box-form .right {
    padding: 20px 10px;
  }
  .login-wrapper {
    padding: 1rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
