<template>
  <div class="loader-wrapper">
    <div class="fancy-spinner">
      <div class="ring"></div>
      <div class="ring"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped>
body {
  background: #f5f5f5;
}
.loader-wrapper {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  /* box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fancy-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
}
.fancy-spinner div {
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.fancy-spinner div.ring {
  border-width: 0.5rem;
  border-style: solid;
  border-color: transparent;
  -webkit-animation: 2s fancy infinite alternate;
  animation: 2s fancy infinite alternate;
}
.fancy-spinner div.ring:nth-child(1) {
  border-left-color: #fff;
  border-right-color: #fff;
}
.fancy-spinner div.ring:nth-child(2) {
  border-top-color: #fff;
  border-bottom-color: #fff;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.fancy-spinner div.dot {
  width: 1rem;
  height: 1rem;
  background: #fff;
}

@-webkit-keyframes fancy {
  to {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes fancy {
  to {
    transform: rotate(360deg) scale(0.5);
  }
}
</style>
