export default {
  getAuthUser: state => {
    return state.user
  },
  getIsUserLogin: state => {
    return state.isUserLogin
  },
  getAllMentee : state=>{
    return state.allMentee
  },
  getAllMentor : state=>{
    return state.allMentor
  },
  getAllLanguages : state=>{
    return state.allLanguages
  },
  getAllNationalities : state=>{
    return state.allNationalities
  },
  getAllIndustries : state=>{
    return state.allIndustries
  },
  getStats:state=>{
    return state.stats
  },
  getAllServices:state=>{
    return state.allServices
  },
  getServices:state=>{
    return state.services
  },
  getAllDegrees:state=>{
    return state.allDegrees
  },
  getAllFeedback:state=>{
    return state.allFeedback
  },
  getAllReports:state=>{
    return state.allReports
  },
  getWelcomeData:state=>{
    return state.welcomeData
  },
  getverifiedEmail: (state) => {
    return state.verifiedEmail;
  },
  getGraphData:(state)=>{
    return state.graphData;
  },
  getUserDetail:(state)=>{
   return  state.userDetail;
  },
  getReportDetail:(state)=>{
    return  state.reportDetail;
  }
}
