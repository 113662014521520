<template>
  <div class="main p-3 d-flex vh-100 justify-content-center align-items-center">
    <div class="box-form">
      <h5>Change Password</h5>
      <div class="inputs">
        <input
          v-model="newPassword"
          type="password"
          placeholder="New Password"
        />
        <div
          class="error text-left px-3"
          v-if="$v.$error && !$v.newPassword.required"
        >
          New Password is required
        </div>
        <div
          class="error text-left px-3"
          v-else-if="$v.$error && !$v.newPassword.minLength"
        >
          New Password should be at least 6 characters long
        </div>
        <div class="mt-3">
          <input
            v-model="confirmPassword"
            type="password"
            placeholder="Confirm New Password"
          />
        </div>
        <div
          class="error text-left px-3"
          v-if="$v.$error && !$v.confirmPassword.required"
        >
          Confirm Password is required
        </div>
        <div
          class="error text-left px-3"
          v-else-if="!$v.$error && newPassword !== confirmPassword"
        >
          Passwords do not match
        </div>
      </div>
      <button @click="changePassword" class="change-button">
        Change Password
      </button>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import helpers from "../helpers";
import { mapGetters } from "vuex";

export default {
  name: "changepassword",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapGetters({ email: "auth/getverifiedEmail" }),
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      required,
    },
  },
  methods: {
    async changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (this.password !== this.confirmpassword) {
        console.log(
          "password",
          this.newPassword,
          "confirm",
          this.confirmPassword
        );
        return;
      }

      let payload = {
        email: this.email,
        password: this.newPassword,
      };
      console.log("payload", payload);
      console.log("email", this.email);

      try {
        await this.$store.dispatch("auth/changePassword", payload).then((x) => {
          if (x.status === 200) {
            this.$router.push("/login");
          } else {
            helpers.errorMessage(this, x.error[0]);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    console.log("verified email", this.email);
  },
};
</script>

<style scoped>
.box-form {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.box-form h5 {
  font-size: 24px;
  margin-bottom: 20px;
}

.inputs {
  margin-bottom: 20px;
}

.inputs input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #0f4a9670;
  border-radius: 5px;
}

.error {
  color: red;
  margin-top: 5px;
}

.change-button {
  display: block;
  width: 100%;
  padding: 12px 24px;
  font-size: 18px;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.change-button:hover {
  background-color: #2980b9;
}
</style>
