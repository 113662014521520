<template>
  <div class="main vh-100 d-flex align-items-center justify-content-center">
    <div class="login-wrapper">
      <div class="box-form">
        <SplashScreen />
        <div class="right">
          <h5 class="title">Verify Your Email</h5>
          <div class="inputs mb-4">
            <input
              v-model="email"
              type="email"
              maxlength="100"
              placeholder="Enter your email"
            />
            <div
              class="error text-left px-3"
              v-if="$v.$error && !$v.email.required"
            >
              Email is required
            </div>
            <div
              class="error text-left px-3"
              v-else-if="$v.$error && !$v.email.email"
            >
              Email is incorrect.
            </div>
          </div>
          <!-- <button @click="verifyEmail" type="submit" class="verify-button">Verify Email</button> -->
          <div class="login-button-wrapper">
            <Button
              label="Verify Email"
              buttonClass="primary-button"
              type="submit"
              @click="handleForgetClick"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
import { required } from "vuelidate/lib/validators";
import helpers from "../helpers";
import SplashScreen from "../components/common/SplashScreen.vue";
import Button from "../components/common/Button.vue";

export default {
  name: "forgotpassword",
  components: {
    SplashScreen,
    Button,
  },
  data() {
    return {
      email: "",
    };
  },
  validations: {
    email: {
      required,
      email: (value) => emailRegex.test(value),
    },
  },
  methods: {
    async handleForgetClick() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let payload = {
        email: this.email,
      };

      try {
        await this.$store.dispatch("auth/verifyemail", payload).then((x) => {
          if (x.status === 200) {
            this.$router.push("/otpverification");
          } else {
            helpers.errorMessage(this, x.error[0]);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.main {
  max-width: 80%;
  margin: auto;
}
.box-form {
  /* width: 80%; */
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.login-wrapper {
  width: 800px;
}
@media (max-width: 980px) {
  .box-form {
    flex-flow: wrap;
    text-align: center;
    align-content: center;
    align-items: center;
    /* height: 85%; */
  }
}

.box-form .right {
  padding: 90px 40px;
  overflow: hidden;
  width: 50%;
}
@media (max-width: 980px) {
  .box-form .right {
    width: 100%;
  }
}
.box-form .right h5 {
  font-size: 2vmax;
  line-height: 0;
  line-height: 33px;
}
.box-form .right p {
  font-size: 14px;
  color: #b0b3b9;
}
.box-form .right .inputs {
  overflow: hidden;
}
.box-form .right input {
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 30px;
  border: 1px solid #0f4a9670 !important;
}
.box-form .right .remember-me--forget-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.box-form .right .remember-me--forget-password input {
  margin: 0;
  margin-right: 7px;
  width: auto;
}
/* .box-form .right button {
  float: right;
  color: #fff;
  font-size: 16px;
  padding: 12px 35px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  outline: 0;
  box-shadow: 0px 4px 20px 0px #3b5998;
  background: #3b5998;
} */
.SplashScreen a {
  color: #3b5998;
  cursor: pointer;
}
label {
  display: block;
  position: relative;
  margin-left: 30px;
}

label::before {
  content: " \f00c";
  position: absolute;
  font-family: FontAwesome;
  background: transparent;
  border: 3px solid #70f570;
  border-radius: 4px;
  color: transparent;
  left: -30px;
  transition: all 0.2s linear;
}

label:hover::before {
  font-family: FontAwesome;
  content: " \f00c";
  color: #fff;
  cursor: pointer;
  background: #70f570;
}

label:hover::before .text-checkbox {
  background: #70f570;
}

label span.text-checkbox {
  display: inline-block;
  height: auto;
  position: relative;
  cursor: pointer;
  transition: all 0.2s linear;
}

label input[type="checkbox"] {
  display: none;
}
.error {
  color: red;
}

.error {
  color: red;
  margin-top: 5px;
}
.login-button-wrapper {
  max-width: 80%;
  margin-inline: auto;
}

.verify-button {
  display: block;
  width: 100%;
  padding: 12px 24px;
  font-size: 18px;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.verify-button:hover {
  background-color: #2980b9;
}

@media (max-width: 991px) {
  .box-form {
    flex-flow: wrap;
    text-align: center;
    align-content: center;
    align-items: center;
  }
  .box-form .right {
    width: 100%;
  }
  .main.custom-login {
    height: 100% !important;
    max-width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .main {
    max-width: 100%;
  }
  .main.custom-login {
    height: 100% !important;
    max-width: 100%;
  }
  .box-form {
    width: 100%;
  }
  .box-form .right {
    padding: 20px 10px;
  }
  .login-wrapper {
    padding: 1rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
