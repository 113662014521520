
<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props:{
    isRender:{
      type: Boolean, default: false
    },
    chartData: {
      type: Object,
      default: () => {},
    }
  },
  watch:{
    isRender(){
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
      });
    }
  },
  mounted () {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
    });
  }

};
</script>
