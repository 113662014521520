<template>
  <div class="Dashboard-wrapper">
    <Loader v-if="isLoading" />
    <UserCard />
    <b-row class="mt-4">
      <b-col cols="12" lg="8">
        <b-card class="mb-4">
          <h4 class="card-title">Users</h4>
          <LineChart
            v-if="isRender"
            :is-render="isRender"
            :chart-data="lineChartData"
            :options="options"
          />
        </b-card>
      </b-col>
      <b-col cols="12" lg="4">
        <b-card class="mb-4">
          <h4 class="card-title">Total User</h4>
          <PieChart
            v-if="isRender"
            :is-render="isRender"
            :chart-data="pieChartData"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import UserCard from "../components/UserCard.vue";
  import PieChart from "../components/DashboardCharts/PieChart";
  import LineChart from "../components/DashboardCharts/LineChart.vue";
  import Loader from "../components/common/Loader.vue";

  export default {
    name: "Dashboard",
    components: {
      UserCard,
      LineChart,
      PieChart,
      Loader,
    },
    data() {
      return {
        isRender: false,
        isLoading: false,
        pieChartData: {
          labels: ["Active User", "Deactive User"],
          datasets: [
            {
              label: "",
              backgroundColor: ["#0F4A96", "#0294D3"],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
        lineChartData: {
          labels: [],
          datasets: [
            {
              label: "Mentors",
              borderColor: "#2962ff",
              pointBackgroundColor: "white",
              borderWidth: 2,
              backgroundColor: "rgba(41, 98, 255, 0.5)",
              data: [],
            },
            {
              label: "Mentees",
              borderColor: "#05CBE1",
              pointBackgroundColor: "white",
              borderWidth: 2,
              backgroundColor: "rgba(255, 255, 255, 0)",
              data: [],
            },
          ],
        },
      };
    },
    computed: {
      ...mapState("auth", ["stats", "graphData"]),
    },
    async mounted() {
      this.isLoading = true;
      // if (
      //   this.stats.activeUsers === 0 ||
      //   this.stats.mentees === 0 ||
      //   this.stats.mentors === 0 ||
      //   this.stats.inactiveUsers === 0 ||
      //   this.stats.totalUsers === 0
      // )

      await this.statsDetails();
      this.prepareChartData();
      this.isRender = true;
      this.isLoading = false;
    },
    methods: {
      async statsDetails() {
        await this.$store.dispatch("auth/getStats");
      },
      prepareChartData() {
        this.pieChartData.datasets[0].data.push(this.stats.activeUsers);
        this.pieChartData.datasets[0].data.push(this.stats.inactiveUsers);
        this.graphData.map((x) => {
          this.lineChartData.labels.push(x.month);
          this.lineChartData.datasets[0].data.push(x.mentors);
          this.lineChartData.datasets[1].data.push(x.mentees);
        });
      },
    },
  };
</script>

<style scoped></style>

