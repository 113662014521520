<template>
  <!-- <div class="overflow-auto"> -->
  <div>
    <h3 style="text-align: start">Mentor Listing</h3>
    <Loader v-if="isLoading" />
    <table ref="mentor" class="table">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Name</th>
          <th scope="col">Phone</th>
          <th scope="col">Email</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(mentor,index) in allMentor" :key="mentor.id">
          <td>{{index + 1}}</td>
          <td>{{ mentor.name }}</td>
          <td>{{ mentor.phone ? mentor.phone : "NA" }}</td>
          <td>{{ mentor.email }}</td>
          <td>{{ mentor.isActive ? "Active" : "Deactive" }}</td>
          <td>
            <span
              v-if="mentor.status"
              @click="openModal(mentor)"
              class="btn btn-outline-success btn-sm cursor ml-2"
              >Activated</span>
            <span
              v-else
              @click="openModal(mentor)"
              class="btn btn-outline-danger btn-sm cursor ml-2"
              >Suspended</span>
            <span
              @click="showMentorDetail(mentor)"
              class="btn btn-outline-secondary btn-sm cursor ml-2"
              >Detail</span>
          </td>
        </tr>
      </tbody>
    </table>
    <ConfirmationModal
      @emitStatusPermission="emitStatusPermission"
      :title="title"
    ></ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import { mapGetters } from "vuex";
import $ from "jquery";
import Loader from "../components/common/Loader.vue";
import helpers from '../helpers'
export default {
  name: "MentorList",
  data() {
    return {
      mentorData: null,
      title: "",
      isLoading: false,
    };
  },
  components: {
    ConfirmationModal,
    Loader,
  },
  computed: {
    ...mapGetters({ allMentor: "auth/getAllMentor" }),
  },
  methods: {
    mentorDetails() {
      this.isLoading = true;
      this.$store.dispatch("auth/getAllMentor").then(() => {
        $(this.$refs.mentor).DataTable({
          bPaginate: true,
          bInfo: false,
          order: false,
          language: {
            search: "", // Set your placeholder text here
            searchPlaceholder: "Search",
          },
          columnDefs: [
            { targets: "_all", orderable: false }, // Disable sorting for all columns
          ],
        });
        this.isLoading = false;
      });
    },
    emitStatusPermission() {
      this.mentorData.status = !this.mentorData.status;
      let payload = {
        userId: this.mentorData._id,
        status: this.mentorData.status,
        isAdmin: true,
      };
      this.$store.dispatch("auth/userStatus", payload).then(() => {
        this.closeModal();
        helpers.customMessage(this,'Success','Status updated successfully.','success');
      });
    },
    openModal(mentor) {
      if (mentor.status) {
        this.title = "Are you sure want to deactivate/suspend this account?";
      } else {
        this.title = "Are you sure want to activate this account?";
      }
      this.mentorData = mentor;
      $("#confirmationModal").modal("show");
    },
    closeModal() {
      $("#confirmationModal").modal("hide");
    },
    async showMentorDetail(mentor) {
      if (mentor) {
        try {
          let mentee = mentor
          await this.$store.dispatch("auth/setUserDetail", { mentee });
          this.$router.push({
            name: "UserDetail",
            params: { id: mentor._id }
          });
        } catch (error) {
          console.error("Error setting mentee details:", error);
        }
      }
    },
  },
  async mounted() {
    await this.mentorDetails();
  },
};
</script>
