<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex gap-3 align-items-center mb-4">
              <router-link to="/reports" class="d-flex align-items-center gap-3">
                <img src="../assets/images/arrow-left.svg" alt="back arrow"  class="cursor-pointer" style="width: 30px"/>
                </router-link>
            <h2 class="mb-0">Report Detail</h2>
          </div>
          <div>
             <p style="text-align: start;margin-bottom: 5px;"><span style="font-weight: bold;font-size: 20px;">Reason</span> : {{reportData.reason}}</p>
             <p style="text-align: start"><span style="font-weight: bold;font-size: 20px">Date & Time</span> : {{dateFormat(reportData.createdAt)}}</p>
          </div>
          <div class="candidate-list">
            <div class="candidate-list-box card mt-4" v-if="reportData.reportBy">
              <div class="p-4 card-body">
                <div class="align-items-center row">
                  <div class="col-auto">
                    <div class="candidate-list-images">
                        <img v-if="reportData.reportBy.images?.profile" :src="reportData.reportBy.images.profile" alt="" class="avatar-md img-thumbnail rounded-circle"/>
                        <img v-else src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="" class="avatar-md img-thumbnail rounded-circle"/>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="candidate-list-content mt-3 mt-lg-0">
                      <h5 class="fs-19 mb-0">
                        Report by: {{reportData.reportBy.name}}
                        <span v-if="reportData.reportBy.rating" class="badge bg-success ms-1"><i class="mdi mdi-star align-middle"></i>{{reportData.reportBy.rating}}</span>
                      </h5>
                      <p class="text-muted mb-2">Type / {{reportData.reportBy.type}}</p>
                      <ul class="list-inline mb-0 text-muted">
                        <li class="list-inline-item d-block">Email :{{reportData.reportBy.email ? reportData.reportBy.email: "NA"}}</li>
                        <li class="list-inline-item d-block">Gender: {{reportData.reportBy.gender ? reportData.reportBy.gender : "NA"}}</li>
                        <li class="list-inline-item d-block">Phone: {{reportData.reportBy.phone ? reportData.reportBy.phone : "NA"}}</li>
                        <li class="list-inline-item d-block">Linked-in Id: {{reportData.reportBy.linkedinId ? reportData.reportBy.linkedinId : "NA"}}</li>
                        <li class="list-inline-item d-block">Status: {{reportData.reportBy.status ? "Active" : "Suspended"}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Speak:</span>
                      <div v-if="reportData.reportBy.speak.length>0">
                        <span v-for="speak in reportData.reportBy.speak" :key="speak._id" class="badge bg-success fs-14 mt-1">{{speak.name}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <br>
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Nationality:</span>
                      <div v-if="reportData.reportBy.nationality.length >0">
                      <span v-for="nationality in reportData.reportBy.nationality" :key="nationality._id" class="badge bg-success fs-14 mt-1">{{nationality.name}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <br>
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Industries:</span>
                      <div v-if="reportData.reportBy.industries.length > 0">
                      <span v-for="industry in reportData.reportBy.industries" :key="industry._id" class="badge bg-success fs-14 mt-1">{{industry.name}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Education:</span>
                      <div v-if="reportData.reportBy.education.length > 0">
                      <span v-for="education in reportData.reportBy.education" :key="education._id" class="badge bg-success fs-14 mt-1">{{education.degree}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Services:</span>
                      <div v-if="reportData.reportBy.service.length > 0">
                      <span v-for="service in reportData.reportBy.service" :key="service._id" class="badge bg-success fs-14 mt-1">{{service.name}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="candidate-list-box card mt-4" v-if="reportData.reportTo">
              <div class="p-4 card-body">
                <div class="align-items-center row">
                  <div class="col-auto">
                    <div class="candidate-list-images">
                      <img v-if="reportData.reportTo.images?.profile" :src="reportData.reportTo.images.profile" alt="" class="avatar-md img-thumbnail rounded-circle"/>
                      <img v-else src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="" class="avatar-md img-thumbnail rounded-circle"/>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="candidate-list-content mt-3 mt-lg-0">
                      <h5 class="fs-19 mb-0">
                        Report to: {{reportData.reportBy.name}}
                        <span v-if="reportData.reportTo.rating" class="badge bg-success ms-1"><i class="mdi mdi-star align-middle"></i>{{reportData.reportTo.rating}}</span>
                      </h5>
                      <p class="text-muted mb-2">Executive, HR Operations</p>
                      <ul class="list-inline mb-0 text-muted">
                        <li class="list-inline-item"><i class="mdi mdi-map-marker"></i> Oakridge Lane Richardson</li>
                        <li class="list-inline-item"><i class="mdi mdi-wallet"></i> $799 / hours</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Speak:</span>
                      <div v-if="reportData.reportBy.speak.length>0">
                        <span v-for="speak in reportData.reportBy.speak" :key="speak._id" class="badge bg-success fs-14 mt-1">{{speak.name}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <br>
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Nationality:</span>
                      <div v-if="reportData.reportBy.nationality.length >0">
                        <span v-for="nationality in reportData.reportBy.nationality" :key="nationality._id" class="badge bg-success fs-14 mt-1">{{nationality.name}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <br>
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Industries:</span>
                      <div v-if="reportData.reportBy.industries.length > 0">
                        <span v-for="industry in reportData.reportBy.industries" :key="industry._id" class="badge bg-success fs-14 mt-1">{{industry.name}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Education:</span>
                      <div v-if="reportData.reportBy.education.length > 0">
                        <span v-for="education in reportData.reportBy.education" :key="education._id" class="badge bg-success fs-14 mt-1">{{education.degree}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                    <div class="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                      <span>Services:</span>
                      <div v-if="reportData.reportBy.service.length > 0">
                        <span v-for="service in reportData.reportBy.service" :key="service._id" class="badge bg-success fs-14 mt-1">{{service.name}}</span>
                      </div>
                      <div v-else>
                        <p class="fs-14 mb-0 custom-text">NA</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name:"ReportDetail",
    data(){
      return{
      }
    },
    computed: {
      ...mapGetters({reportData: "auth/getReportDetail"})
    },
    methods:{
      dateFormat(originalDateString){
        const originalDate = new Date(originalDateString);
        const formattedTime = originalDate.toLocaleTimeString([], { hour12: false });
        const formattedDate = originalDate.toLocaleDateString('en-CA'); // 'en-CA' uses the format 'yyyy/MM/dd'
        return `${formattedTime} ${formattedDate}`;
      },
    }
  }
</script>
<style scoped>
  body {
    padding-top: 40px;
    background: #e6e6fa;
  }
  .card {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
  }

  .avatar-md {
    height: 8rem;
    width: 8rem;
  }
  .candidate-list-box .about-wrapper > span {
    min-width: 100px;
    text-align: left;
    line-height: 25px;
  }
  .right-content-custom .custom-text {
    line-height: 25px;
  }
  .fs-19 {
    font-size: 19px;
  }

  .primary-link {
    color: #314047;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  a {
    color: #02af74;
    text-decoration: none;
  }

  .bookmark-post .favorite-icon a,
  .job-box.bookmark-post .favorite-icon a {
    background-color: #da3746;
    color: #fff;
    border-color: danger;
  }
  .favorite-icon a {
    display: inline-block;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #eff0f2;
    border-radius: 6px;
    color: rgba(173, 181, 189, 0.55);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .candidate-list-box .favorite-icon {
    position: absolute;
    right: 22px;
    top: 22px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .bg-soft-secondary {
    background-color: rgba(116, 120, 141, 0.15) !important;
    color: #74788d !important;
  }

  .mt-1 {
    margin-top: 0.25rem !important;
  }
</style>
