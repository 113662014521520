<template>
  <div>
    <!-- Hamburger menu button -->
    <button class="hamburger-button" @click="toggleSidebar" :class="{ active: buttonActive }">
      <div class="hamburger-icon">
        <div class="bar-line"></div>
        <div class="bar-line"></div>
        <div class="bar-line"></div>
      </div>
    </button>
    <!-- sidebar  -->


    <div class="sidebar-card text-start" :class="sidebarClass">
      <div class="py-3">
        <b-nav vertical>
          <!-- <b-nav-item ><b-link to="/dashboard">Dashboard</b-link></b-nav-item> -->
          <router-link to="/dashboard" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/dashboard')" @mouseout="toggleHover('/dashboard')">
              <img :src="getImageSource('/dashboard')" alt="dashbaord icon" />
              Dashboard
            </span>
          </router-link>
          <router-link to="/mentee" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/mentee')" @mouseout="toggleHover('/mentee')">
              <img :src="getImageSource('/mentee')" alt="mentee icon" />
              Mentee
            </span>
          </router-link>
          <router-link to="/mentor" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/mentor')" @mouseout="toggleHover('/mentor')">
              <img :src="getImageSource('/mentor')" alt="mentor icon" />
              Mentor
            </span>
          </router-link>
          <router-link to="/languages" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/languages')" @mouseout="toggleHover('/languages')">
              <img :src="getImageSource('/languages')" alt="Languages icon" />
              Languages
            </span>
          </router-link>
          <router-link to="/nationalities" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/nationalities')"
              @mouseout="toggleHover('/nationalities')">
              <img :src="getImageSource('/nationalities')" alt="Nationalities icon" />
              Nationalities
            </span>
          </router-link>
          <router-link to="/industries" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/industries')" @mouseout="toggleHover('/industries')">
              <img :src="getImageSource('/industries')" alt="Industries icon" />
              Industries
            </span>
          </router-link>
          <router-link to="/degrees" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/degrees')" @mouseout="toggleHover('/degrees')">
              <img :src="getImageSource('/degrees')" alt="degrees icon" />
              Degree's
            </span>
          </router-link>
          <router-link to="/services" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/services')" @mouseout="toggleHover('/services')">
              <img :src="getImageSource('/services')" alt="services icon" />
              Services
            </span>
          </router-link>
          <router-link to="/feedback" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/feedback')" @mouseout="toggleHover('/feedback')">
              <img :src="getImageSource('/feedback')" alt="feedback icon" />
              FeedBack
            </span>
          </router-link>
          <router-link to="/reports" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/reports')" @mouseout="toggleHover('/reports')">
              <img :src="getImageSource('/reports')" alt="reports icon" />
              Reports
            </span>
          </router-link>
          <router-link to="/welcome/text" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/welcome/text')"
              @mouseout="toggleHover('/welcome/text')">
              <img :src="getImageSource('/welcome/text')" alt="welcome icon" />
              Welcome Text
            </span>
          </router-link>
          <router-link to="/announcement" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/announcement')"
                  @mouseout="toggleHover('/announcement')">
              <img :src="getImageSource('/announcement')" alt="announcement" />
              Announcement
            </span>
          </router-link>
          <router-link to="/chat" class="nav-link" active-class="active-nav-link" exact>
            <span class="d-block w-100" @mouseover="toggleHover('/chat')" @mouseout="toggleHover('/chat')">
              <img :src="getImageSource('/chat')" alt="chat icon" />
              Chat
            </span>
          </router-link>
        </b-nav>
      </div>
    </div>
  </div>
</template>
<script>
import dashboardIcon from "../assets/images/dashboard-icon.svg";
import dashboardWhiteIcon from "../assets/images/dashboard-icon-white.svg";
import singleUserIcon from "../assets/images/single-user-icon.svg";
import singleUserWhiteIcon from "../assets/images/single-user-icon-white.svg";
import multiUserIcon from "../assets/images/multi-user-icon.svg";
import multiUserWhiteIcon from "../assets/images/multi-user-icon-white.svg";
import reportIcon from "../assets/images/report-icon.svg";
import reportWhiteIcon from "../assets/images/report-icon-white.svg";
import servicesIcon from "../assets/images/services-icon.svg";
import servicesWhiteIcon from "../assets/images/services-icon-white.svg";
import degreesIcon from "../assets/images/degrees-icon.svg";
import degreesWhiteIcon from "../assets/images/degrees-icon-white.svg";
import feedbackIcon from "../assets/images/feedback-icon.svg";
import feedbackWhiteIcon from "../assets/images/feedback-icon-white.svg";
import nationalityIcon from "../assets/images/nationality-icon.svg";
import nationalityWhiteIcon from "../assets/images/nationality-icon-white.svg";
import chatIcon from "../assets/images/chat-icon.svg";
import chatWhiteIcon from "../assets/images/chat-icon-white.svg";
import industriesIcon from "../assets/images/industries-icon.svg";
import industriesWhiteIcon from "../assets/images/industries-icon-white.svg";
import flagIcon from "../assets/images/flag-icon.svg";
import flagWhiteIcon from "../assets/images/flag-icon-white.svg";
import welcomeIcon from "../assets/images/welcome-icon.svg";
import welcomeWhiteIcon from "../assets/images/welcome-icon-white.svg";

export default {
  name: "Sidebar",
  data() {
    return {
      sidebarVisible: true,
      buttonActive: false,
      hoverStates: {
        "/dashboard": false,
        "/mentee": false,
        "/mentor": false,
        "/languages": false,
        "/nationalities": false,
        "/industries": false,
        "/degrees": false,
        "/services": false,
        "/feedback": false,
        "/reports": false,
        "/welcome/text": false,
        "/chat": false,
        "/announcement":false,
      },
      images: {
        "/dashboard": {
          default: dashboardIcon,
          hover: dashboardWhiteIcon,
          active: dashboardWhiteIcon,
        },
        "/mentee": {
          default: singleUserIcon,
          hover: singleUserWhiteIcon,
          active: singleUserWhiteIcon,
        },
        "/mentor": {
          default: multiUserIcon,
          hover: multiUserWhiteIcon,
          active: multiUserWhiteIcon,
        },
        "/languages": {
          default: flagIcon,
          hover: flagWhiteIcon,
          active: flagWhiteIcon,
        },
        "/nationalities": {
          default: nationalityIcon,
          hover: nationalityWhiteIcon,
          active: nationalityWhiteIcon,
        },
        "/industries": {
          default: industriesIcon,
          hover: industriesWhiteIcon,
          active: industriesWhiteIcon,
        },
        "/degrees": {
          default: degreesIcon,
          hover: degreesWhiteIcon,
          active: degreesWhiteIcon,
        },
        "/services": {
          default: servicesIcon,
          hover: servicesWhiteIcon,
          active: servicesWhiteIcon,
        },
        "/feedback": {
          default: feedbackIcon,
          hover: feedbackWhiteIcon,
          active: feedbackWhiteIcon,
        },
        "/reports": {
          default: reportIcon,
          hover: reportWhiteIcon,
          active: reportWhiteIcon,
        },
        "/welcome/text": {
          default: welcomeIcon,
          hover: welcomeWhiteIcon,
          active: welcomeWhiteIcon,
        },
        "/announcement": {
          default: flagIcon,
          hover: flagWhiteIcon,
          active: flagWhiteIcon,
        },
        "/chat": {
          default: chatIcon,
          hover: chatWhiteIcon,
          active: chatWhiteIcon,
        },
      },
    };

  },
  methods: {
    toggleHover(route) {
      this.hoverStates[route] = !this.hoverStates[route];
    },
    toggleSidebar() {
  this.sidebarVisible = !this.sidebarVisible;
  this.buttonActive = !this.buttonActive;
  const hamburgerIcon = document.querySelector('.hamburger-icon');
  if (hamburgerIcon) {
    hamburgerIcon.classList.toggle('active', this.sidebarVisible);
  }
   },
    isActive(route) {
      return this.$route.path === route;
    },
    getImageSource(route) {
      const image = this.images[route];
      if (!image) {
        return null;
      }
      return this.hoverStates[route] || this.isActive(route)
        ? image.hover
        : image.default;
    },
    startSessionTimeout() {
      const timeoutDuration = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
      this.timeout = setTimeout(() => {
        this.logout();
      }, timeoutDuration);
    },
    logout() {
      this.$store.dispatch("auth/routeLogout").then(() => {
        this.$router.push("/");
      });
    },
    resetSessionTimeout() {
      clearTimeout(this.timeout);
      this.startSessionTimeout();
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.resetSessionTimeout);
    window.addEventListener('keydown', this.resetSessionTimeout);
    window.addEventListener('touchstart', this.resetSessionTimeout);
    this.startSessionTimeout();
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.resetSessionTimeout);
    window.removeEventListener('keydown', this.resetSessionTimeout);
    window.removeEventListener('touchstart', this.resetSessionTimeout);
    clearTimeout(this.timeout);
  },
  computed: {
  sidebarClass() {
    return this.sidebarVisible ? 'sidebar-card' : 'sidebar-hidden';
  }
},
};
</script>
<style>
/* .sidebar-card{
  text-align: center;
} */

/* Add styles for the hamburger button and icon */
.hamburger-button {
  display: none;
  position: fixed;
  top: 24px;
  left: 16px;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 30px;
}

.bar-line {
  width: 100%;
  height: 3px;
  background-color: #333;
  margin: 2px 0;
  transition: transform 0.2s;
}

.hamburger-button.active .bar-line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger-button.active .bar-line:nth-child(2) {
  /* opacity: 0; */
  display: none;
}

.hamburger-button.active .bar-line:nth-child(3) {
  transform: translateY(1px) rotate(-45deg);
}

/* Add styles to hide the sidebar and adjust content width */
@media screen and (max-width: 1199px) {
  .sidebar-card{
    z-index: 999;
  }
  .hamburger-button {
  display: block;
  }
  /* .sidebar-card {
    transform: translateX(-100%);
    transition: transform 0.3s;
  } */

  .sidebar-hidden {
    transform: translateX(0) !important;
  }
  .sidebar-card {
    transform: translateX(-100%);
    transition: transform 0.3s;
  }
  .content {
    margin-left: 0;
  }
}
</style>
