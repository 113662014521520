<template>
  <button
    :class="buttonClass"
    class="Button-wrapper"
    :type="type"
    @click="handleClick"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: String,
    buttonClass: String,
    type: {
      type: String,
      default: "button",
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.Button-wrapper {
  width: 100%;
  color: #fff;
  font-size: 16px;
  padding: 10px 35px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  outline: 0;
  box-shadow: 0px 4px 20px 0px #3b5998;
  background: #3b5998;
}
</style>
