<template>
  <div>
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Languages</h3>
      <button @click="addLanguage" class="btn btn-sm btn-outline-info mr-2">
        Add Language
      </button>
    </div>
    <div class="table-responsive">
      <table ref="languages" class="table">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(language,index) in allLanguages" :key="language.id">
            <td>{{ index + 1 }}</td>
            <td>{{ language.name }}</td>
            <td>
              <button
                @click="editLanguage(language)"
                type="button"
                class="btn btn-sm btn-outline-info mr-2"
              >
                Edit
              </button>
              <button
                @click="openConfirmationModal(language._id)"
                type="button"
                class="btn btn-sm btn-outline-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Loader v-if="isLoading" />
    <LanguageModal
      @emitSaveLanguage="submitSaveLanguage"
      @close="closeModal"
      :selectedLanguage="selectedLanguage"
    ></LanguageModal>
    <ConfirmationModal
      @emitStatusPermission="emitDeleteLanguage"
      :title="title"
    ></ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import LanguageModal from "../components/Modal/LanguageModal";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import { mapGetters } from "vuex";
import helpers from "../helpers";
import Loader from "../components/common/Loader.vue";
export default {
  directives: {
    paginate_button: {
      bind(el) {
        el.addEventListener("click", () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // You can use 'auto' for an instant scroll
          });
        });
      },
    },
  },
  data() {
    return {
      selectedLanguage: {
        id: "",
        name: "",
      },
      languageId: "",
      title: "",
      isLoading: false,
    };
  },
  components: {
    LanguageModal,
    ConfirmationModal,
    Loader,
  },
  computed: {
    ...mapGetters({ allLanguages: "auth/getAllLanguages" }),
  },
  methods: {
    empty() {
      this.selectedLanguage = {
        id: "",
        name: "",
      };
    },
    languagesDetails() {
      this.isLoading = true;
      this.$store.dispatch("auth/getAllLanguages").then(() => {
        this.dateTableSet();
        this.isLoading = false;
      });
    },
    submitSaveLanguage(name) {
      if (this.selectedLanguage.name) {
        let payload = {
          id: this.selectedLanguage._id,
          name: name,
        };
        this.$store.dispatch("auth/updateLanguage", payload).then((x) => {
          if (x.status === 200) {
            this.updateDataTable();
            this.empty();
            this.closeModal();
            helpers.customMessage(this,'Success','Language updated successfully.','success');
          } else {
            helpers.customMessage(this,'Error',x.error[0].message,'error');
          }
        });
      } else {
        let payload = {
          name: name,
        };
        this.$store.dispatch("auth/saveLanguage", payload).then((x) => {
          if (x.status === 200) {
            this.updateDataTable();
            this.closeModal();
            helpers.customMessage(this,'Success','Language added successfully.','success');
          } else {
            helpers.customMessage(this,'Error',x.error[0].message,'error');
          }
        });
      }
    },
    dateTableSet(){
      $(this.$refs.languages).DataTable({
        bPaginate: true,
        bInfo: false,
        order: false,
        language: {
          search: "", // Set your placeholder text here
          searchPlaceholder: "Search",
        },
        columnDefs: [
          { targets: "_all", orderable: false }, // Disable sorting for all columns
        ],
      });
    },
    updateDataTable() {
      if ($.fn.DataTable.isDataTable(this.$refs.languages)) {
          // Destroy the DataTable instance
          $(this.$refs.languages).DataTable().destroy();
          // Recreate the DataTable with the updated data
         this.dateTableSet();
      }
    },

    emitDeleteLanguage() {
      let payload = {
        id: this.languageId,
      };
      this.$store.dispatch("auth/deleteLanguage", payload).then((x) => {
        if (x.status === 200) {
          this.closeConfirmationModal();
          helpers.customMessage(this,'Success','Language deleted successfully.','success');
        } else {
          helpers.customMessage(this,'Error',x.error[0].message,'error');
        }
      });
    },
    addLanguage() {
      this.empty();
      this.openModal();
    },
    openModal() {
      $("#languageModal").modal("show");
    },
    closeModal() {
      $("#languageModal").modal("hide");
    },
    editLanguage(language) {
      this.selectedLanguage = language;
      this.openModal();
    },
    openConfirmationModal(languageId) {
      this.title = "Are you sure want to delete this language?";
      this.languageId = languageId;
      $("#confirmationModal").modal("show");
    },
    closeConfirmationModal() {
      $("#confirmationModal").modal("hide");
    },
  },
  async mounted() {
    await this.languagesDetails();
  },
};
</script>
