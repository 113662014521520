<template>
  <div class="main-wrapper">
    <b-container fluid>
      <NavbarNew />
      <b-row class="Admin-layout-wrapper">
        <!-- Sidebar -->
        <b-col md="2" class="pr-0">
          <Sidebar />
        </b-col>
        <!-- Main Content -->
        <b-col md="12" xl="10">
          <div class="content">
            <router-view />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import NavbarNew from "../components/NavbarNew.vue";
export default {
  name: "AdminLayout",
  components: {
    Sidebar,
    NavbarNew,
    // Register the Sidebar component
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/routeLogout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style>
.Admin-layout-wrapper {
  padding-top: 56px;
}
.navbar-warapper {
  background: linear-gradient(180deg, #e6eaee 0%, #d5dee8 100%);
}
.navbar-warapper .dropdown-item:hover,
.navbar-warapper .dropdown-item:focus {
  background-color: #0f4a96 !important;
  color: #fff !important;
}

.sidebar-card {
  height: 100vh;
  /* Set the card height to fill the sidebar */
  background: linear-gradient(180deg, #e6eaee 0%, #d5dee8 100%);
  /* Add additional styling for the card as needed */
  position: fixed;
  width: 100%;
  overflow-y: auto;
  max-width: 237px;
}

.content {
  /* height: 100vh; */
  padding: 40px;
  /* Add styling for the main content area as needed */
}

.logo {
  text-align: start;
}
.logo img {
  width: 85px;
}

.active-nav-link {
  padding: 0.5rem 1rem;
  display: block;
  background: #0f4a96 !important;
  color: #fff !important;
}
.profile-icon {
  width: 40px;
  height: 40px;
}
.mdi-bell::before {
  font-size: 20px !important;
  color: #0f4a96;
}
</style>
