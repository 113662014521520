<template>
  <div class="modal fade" id="degreeModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">
            {{ selectedDegree.name ? "Edit Degree" : "Add Degree" }}
          </h4>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-group">
            <input
              v-model="name"
              type="text"
              maxlength="50"
              placeholder="Enter degree name"
              required
              class="form-control"
            />
            <div
              class="error text-danger"
              v-if="$v.name.$error && !$v.name.required"
            >
              Degree is required
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button @click="saveDegree" type="button" class="btn btn-outline-info">
            {{ selectedDegree.name ? "Update" : "Save" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "Degree",
  props: {
    selectedDegree: Object,
  },
  data() {
    return {
      name: "",
    };
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    saveDegree() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit("emitSaveDegree", this.name);
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    selectedDegree(val) {
      this.name = val.name;
    },
  },
};
</script>
