<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="mb-3">
        <h5 for="exampleFormControlTextarea" class="form-label mb-4 text-left">
          Announcement
        </h5>
        <div class="card mb-4">
          <div class="card-header bg-white d-flex justify-content-center" @click="toggleCollapse">
            <h5 class="mb-0 ml-2">
              {{ isCollapsed ? 'Show Mentees' : 'Hide Mentees' }}
            </h5>
          </div>
          <div v-if="!isCollapsed">

            <div class="card-body">
              <div class="select-all-custom">
                <input type="checkbox" v-model="selectAllMentee" @change="selectAllCheckboxes"> Select All
              </div>
              <div class="table-responsive">
                <table ref="mentee" class="table">
                  <thead>
                  <tr>
                    <th scope="col">Selection</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="mentee in allMentee" :key="mentee.id">
                    <td>
                      <input
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        v-model="mentee.isSelected"
                        @change="updateSelectedIds(mentee._id,mentee.isSelected)">
                    </td>
                    <td>{{ mentee.name }}</td>
                    <td>{{ mentee.phone ? mentee.phone : "NA" }}</td>
                    <td>{{ mentee.email }}</td>
                    <td>
                      {{ mentee.isActive ? "Active" : "Deactive" }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-4 ">
          <div class="card-header bg-white d-flex justify-content-center" @click="toggleMentorCollapse">
            <h5 class="mb-0 ml-2">
              {{ isMentorCollapsed ? 'Show Mentors' : 'Hide Mentors' }}
            </h5>
          </div>
          <div v-if="!isMentorCollapsed">
            <div class="card-body">

              <div class="select-all-custom">
                <input type="checkbox" v-model="selectAllMentor" @change="selectAllMentorCheckboxes"> Select All
              </div>
              <table ref="mentor" class="table">
                <thead>
                <tr>
                  <th scope="col">Selection</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(mentor) in allMentor" :key="mentor.id">
                  <td>
                    <input
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      v-model="mentor.isSelected"
                      @change="updateSelectedIds(mentor._id,mentor.isSelected)">
                  </td>
                  <td>{{ mentor.name }}</td>
                  <td>{{ mentor.phone ? mentor.phone : "NA" }}</td>
                  <td>{{ mentor.email }}</td>
                  <td>{{ mentor.isActive ? "Active" : "Deactive" }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <textarea
          style="border-radius: 5px !important"
          v-model="announcementText"
          class="form-control rounded-lg"
          placeholder="Enter message here for announcement"
          id="exampleFormControlTextarea"
          rows="5">
        </textarea>
      </div>
    </div>
    <div class="text-right">
      <button @click="clearText" class="btn btn-outline-info mr-2">
        Clear
      </button>
      <button @click="saveText" class="btn btn-outline-info px-4">
        Send
      </button>
    </div>
    <Loader v-if="isLoading"/>
  </div>
</template>
<script>
  import helpers from "../helpers";
  import Loader from "../components/common/Loader.vue";
  import { mapGetters } from 'vuex'
  export default {
    name: "Announcement",
    components: {
      Loader,
    },
    data() {
      return {
        announcementText: "",
        isLoading: false,
        isCollapsed: true,
        isMentorCollapsed: true,
        selectAllMentee:false,
        selectAllMentor:false,
        selectedIds:[]
      };
    },
    computed: {
      ...mapGetters({ allMentee: "auth/getAllMentee",allMentor: "auth/getAllMentor"}),
    },
    methods: {
      updateSelectedIds(Id, isSelected) {
        console.log(`Updating selection for ID ${Id}. isSelected: ${isSelected}`);
        console.log("Selected IDs:", this.selectedIds);

        if (isSelected) {
          this.selectedIds.push(Id);
        } else {
          // Unselect the mentee/mentor (remove from the array)
          this.selectedIds = this.selectedIds.filter((id) => id !== Id);
          console.log("Selected IDs:", this.selectedIds);
        }
      },

      selectAllCheckboxes() {
        this.allMentee.forEach((item) => {
          item.isSelected = this.selectAllMentee;
          this.updateSelectedIds(item._id, item.isSelected);
        });
      },

      selectAllMentorCheckboxes() {
        this.allMentor.forEach((item) => {
          item.isSelected = this.selectAllMentor;
          this.updateSelectedIds(item._id, item.isSelected);
        });
      },

      mentorDetails() {
        this.$store.dispatch("auth/getAllMentor");
      },
      menteeDetails() {
        this.$store.dispatch("auth/getAllMentee");
      },
      toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
      },
      toggleMentorCollapse() {
        this.isMentorCollapsed = !this.isMentorCollapsed;
      },
      clearText() {
        this.announcementText = "";
      },
      async saveText() {
        if (this.announcementText === "") {
          return helpers.customMessage(this,'Error','Please enter announcement message first.','error');
        }
        if (this.selectedIds.length === 0) {
          return helpers.customMessage(this,'Error','Please select any user first.','error');
        }
        this.isLoading = true;
        const payload = {
          announcementText: this.announcementText,
          selectedIds:this.selectedIds
        };
        try {
          const response = await this.$store.dispatch("auth/sendAnnouncementMessage", payload);
          if (response.status === 200) {
            // Clear the selectedIds array
            this.selectedIds = [];
            this.selectAllMentee = false;
            this.selectAllMentor = false;
            // Unselect all checkboxes in allMentee
            this.allMentee.forEach((item) => {
              item.isSelected = false;
            });
            // Unselect all checkboxes in allMentor
            this.allMentor.forEach((item) => {
              item.isSelected = false;
            });
            this.isLoading = false;
            helpers.customMessage(this,'Success','Announcement message was sent successfully to all users.','success');
            this.announcementText = "";
          } else {
            helpers.customMessage(this,'Error',response.error[0],'error');
          }
        } catch (error) {
          helpers.errorMessage(this, error.message || "Error saving data");
        }
      },
    },
   async mounted () {
      await this.menteeDetails();
      await this.mentorDetails();
    }
  };
</script>
<style scoped>
  .select-all-custom{
    text-align: left;
    padding-left: 20px;
  }
</style>
