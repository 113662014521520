// src/firebaseConfig.js
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDdefgkregOdyuFdtCzn1Rt-qfJVmXy6nw",
  authDomain: "move-mentor.firebaseapp.com",
  projectId: "move-mentor",
  storageBucket: "move-mentor.appspot.com",
  messagingSenderId: "986187527532",
  appId: "1:986187527532:web:ff203ea846379a5ab22f49",
  measurementId: "G-65FHKKXFJ9"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage = firebase.storage();
const firestore = firebase.firestore();

export { auth, firestore,storage };

